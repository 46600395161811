import { Layout, Section } from "../components/section";

import { AppLinks } from "../components/links";
import { Title } from "../components/typography";

export const Logout = () => {
  return (
    <Layout>
      <Section>
        <Title number={2}>Logout Successful</Title>
      </Section>
      <AppLinks to="/">Return to login page</AppLinks>
    </Layout>
  );
};
