export const STAFF_REQUEST = "STAFF_REQUEST";
export const STAFF_ERROR = "STAFF_ERROR";

export const STAFFS_FETCH = "STAFFS_FETCH";
export const STAFF_FETCH = "STAFF_FETCH";
export const STAFF_CREATE = "STAFF_CREATE";
export const STAFF_EDIT = "STAFF_EDIT";
export const STAFF_DELETE = "STAFF_DELETE";
export const STAFF_SEARCH = "STAFF_SEARCH";
export const STAFF_DELETE_IMAGE = "STAFF_DELETE_IMAGE";
