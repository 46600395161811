import { DeleteBlog, UpdateBlog } from "../../redux/blog";

import { BlogForm } from "./BlogForm";
import { IoTrash } from "react-icons/io5";
import { Section } from "../../components/section";
import { Text } from "../../components/typography";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export const BlogEdit = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save your edits?`)) {
      dispatch(UpdateBlog(state.blog.id, values));
    }
  };

  const onDeleteSubmit = () => {
    if (window.confirm(`Are you sure you want to delete this?`)) {
      dispatch(DeleteBlog(state.blog));
    }
  };

  return (
    <Section>
      <SubNav>
        <li onClick={onDeleteSubmit}>
          <IoTrash />
          <Text>Delete</Text>
        </li>
      </SubNav>
      <BlogForm initialValues={state.blog} onFormSubmit={onFormSubmit} />
    </Section>
  );
};

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    color: red;

    p {
      margin: 0;
      padding: 0 0 0 0.5rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
