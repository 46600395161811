import {
  Profile,
  ResourcesDashboard,
  UserAccountFormDetail,
  UsersList,
} from "../elements/resources";
import { Resources, Users } from "../pages";

import { Route } from "react-router-dom";

const SettingsRoute = () => {
  return (
    <>
      <Route path="settings" element={<Resources />}>
        <Route index element={<ResourcesDashboard />} />
        <Route path="profile" element={<Profile />} />
        <Route path="users" element={<Users />}>
          <Route index element={<UsersList />} />
          <Route path=":userName/edit" element={<UserAccountFormDetail />} />
        </Route>
      </Route>
    </>
  );
};

export default SettingsRoute;
