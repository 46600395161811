import { Field, Form } from "react-final-form";
import { Text, Title } from "../../components/typography";

import { Button } from "../../components/button";
import { Grid } from "../../components/section";
import { Input } from "../../components/inputs";
import { UserSignUp } from "../../redux/user/UserActions";
import { useDispatch } from "react-redux";

export const SignUpForm = (props) => {
  const { error } = props;
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(UserSignUp(values));
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};

        if (!values.userName || values.userName.trim().length === 0) {
          errors.userName = "Username required";
        }
        if (!values.firstName || values.firstName.trim().length === 0) {
          errors.firstName = "First name required";
        }
        if (!values.lastName || values.lastName.trim().length === 0) {
          errors.lastName = "Last name required";
        }
        if (!values.email || values.email.trim().length === 0) {
          errors.email = "Email address required";
        } else if (
          values.email &&
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }
        if (!values.password || values.password.trim().length === 0) {
          errors.password = "Password Required";
        } else if (values.password !== values.confirmPassword) {
          errors.confirmPassword = "Password must match";
        }

        return errors;
      }}
    >
      {({ handleSubmit, pristine, submitting, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Title number={4}>Sign Up</Title>
          <Text scale="small">
            Authorized personnel only. All others will be prosecuted to the
            extent of the law.
          </Text>
          {error && <Text color="red">{error}</Text>}

          <Field
            label="Username"
            name="userName"
            type="text"
            component={Input}
            autoComplete="username"
            required
          />
          <Field
            label="Email"
            name="email"
            type="email"
            component={Input}
            autoComplete="email"
            required
          />
          <Grid>
            <Field
              label="Password"
              name="password"
              type="password"
              component={Input}
              autoComplete="new-password"
              required
            />
            <Field
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              component={Input}
              autoComplete="new-password"
              required
            />
          </Grid>

          <Grid>
            <Field
              name="firstName"
              type="text"
              component={Input}
              label="First Name"
              required
            />
            <Field
              name="lastName"
              type="text"
              component={Input}
              label="Last Name"
              required
            />
          </Grid>

          <div>
            <Button
              type="submit"
              disabled={submitting || pristine || invalid}
              text={submitting ? "Submitting....." : "Create Account"}
            />
          </div>
        </form>
      )}
    </Form>
  );
};

export default SignUpForm;
