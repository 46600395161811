import { colors, responsiveness } from "../assets/styles/parameters";

import { SignUpForm } from "../elements/signup";
import { Text } from "../components/typography";
import img from "../assets/imgs/cford.webp";
import logo from "../assets/imgs/cford.webp";
import styled from "styled-components";

export const SignUp = () => {
  return (
    <Wrapper background={img}>
      <div>
        <img src={logo} alt="Logo" />
        <SignUpForm error={""} />
        <BaseFooterSub scale="sm">
          &copy; Copyright
          <span className="year">{new Date().getFullYear()}</span> Extreme Clean
          Environmental Solutions 501(c) (3) - All Rights Reserved
        </BaseFooterSub>
      </div>
    </Wrapper>
  );
};

export default SignUp;

const Wrapper = styled.section`
  background: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  height: 100vh;

  img {
    text-align: center;
    width: 10rem;
  }

  & > div:first-child {
    background-color: ${colors.gray100};

    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
    grid-auto-flow: dense;
  }
`;

const BaseFooterSub = styled(Text)`
  color: ${colors.gray600};
  font-size: 0.5rem;
  padding: 0;
  margin: 4rem 0 0 0;
`;
