import { Field, Form } from "react-final-form";
import { Text, Title } from "../../components/typography";

import { Button } from "../../components/button";
import { Component } from "react";
import { Input } from "../../components/inputs";
import { UserLogIn } from "../../redux/user/UserActions";
import { connect } from "react-redux";
import styled from "styled-components";

class LoginForm extends Component {
  state = {
    values: this.props.initialValues || {},
  };

  onSubmitValues = (values) => {
    this.props.UserLogIn(values);
  };

  render() {
    return (
      <Form
        initialValues={
          process.env.NODE_ENV !== "production"
            ? { email: "test@gmail.com", password: "123" }
            : { email: "", password: "" }
        }
        onSubmit={this.onSubmitValues}
        validate={(values) => {
          const errors = {};

          if (!values.email) {
            errors.email = "You must enter your email.";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.password) {
            errors.password = "You must enter your password.";
          }

          return errors;
        }}
      >
        {({ handleSubmit, pristine, submitting, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Title number={11}>Sign In</Title>
            <Text scale="small">
              Authorized personnel only. All others will be prosecuted to the
              extent of the law.
            </Text>
            {this.props.error && (
              <Text scale="small" color="red">
                {this.props.error}
              </Text>
            )}
            <Field
              label="Email"
              name="email"
              type="email"
              component={Input}
              required
              autoComplete="on"
            />
            <Field
              label="Password"
              name="password"
              type="password"
              component={Input}
              autoComplete="current-password"
              required
            />
            <InfoFlex>
              <Button
                type="submit"
                varient="outlineBK"
                disabled={submitting || invalid}
                text={submitting ? "Submitting....." : "Login"}
              />
              <Text
                scale="small"
                color="gray"
                onClick={() => this.props.setPassReq()}
                style={{ cursor: "pointer" }}
              >
                Can't login
              </Text>
            </InfoFlex>
          </form>
        )}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return { userAuth: state.users };
};

export default connect(mapStateToProps, { UserLogIn })(LoginForm);

const InfoFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 2rem;

  p,
  button,
  a {
    margin: 0;
    width: fit-content;
  }
`;
