import {
  IoBonfireOutline,
  IoCalendarNumberOutline,
  IoCameraOutline,
  IoChatbubblesOutline,
  IoGlobeSharp,
  IoNewspaperOutline,
  IoPeopleOutline,
  IoPersonAddOutline,
  IoStarOutline,
} from "react-icons/io5";

export const MainPaths = [
  {
    name: "Dashboard",
    path: "/",
    icon: <IoGlobeSharp />,
  },
  {
    name: "Experiences",
    path: "/about/experiences",
    icon: <IoPeopleOutline />,
  },
  {
    name: "Events",
    path: "/events",
    icon: <IoCalendarNumberOutline />,
  },
  {
    name: "Press",
    path: "/press",
    icon: <IoNewspaperOutline />,
  },

  {
    name: "Messages",
    path: "/messages",
    icon: <IoChatbubblesOutline />,
  },
];

export const SidebarPath = [
  {
    name: "Dashboard",
    path: "/",
    icon: <IoGlobeSharp />,
  },
  {
    name: "About",
    path: "/about",
    icon: <IoPersonAddOutline />,
  },
  {
    name: "Awards",
    path: "/about/awards",
    icon: <IoStarOutline />,
  },
  {
    name: "Experiences",
    path: "/about/experiences",
    icon: <IoBonfireOutline />,
  },
  {
    name: "Events",
    path: "/events",
    icon: <IoCalendarNumberOutline />,
  },
  {
    name: "Gallery",
    path: "/gallery",
    icon: <IoCameraOutline />,
  },
  {
    name: "Messages",
    path: "/messages",
    icon: <IoChatbubblesOutline />,
  },
  {
    name: "Press",
    path: "/press",
    icon: <IoNewspaperOutline />,
  },
];
