import { cfAPI } from "../api";
import { toast } from "react-toastify";

export const BLOG_REQUEST = "BLOG_REQUEST";
export const BLOG_ERROR = "BLOG_ERROR";
export const BLOGS_FETCH = "BLOGS_FETCH";
export const BLOG_FETCH = "BLOG_FETCH";
export const BLOG_CREATE = "BLOG_CREATE";
export const BLOG_EDIT = "BLOG_EDIT";
export const BLOG_DELETE = "BLOG_DELETE";

const INITIAL_STATE = {
  loading: false,
  blogs: [],
  blog: {},
  error: "",
};

const BaseRoute = "cfwebapi/api/v1/blogs";

export const CreateBlog = (values) => async (dispatch) => {
  try {
    dispatch({
      type: BLOG_REQUEST,
    });

    const response = await cfAPI.post(BaseRoute, values);

    dispatch({
      type: BLOG_CREATE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: BLOG_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const FetchBlogs = () => async (dispatch) => {
  try {
    dispatch({
      type: BLOG_REQUEST,
    });

    const response = await cfAPI.get(BaseRoute);

    console.log(response);
    dispatch({
      type: BLOGS_FETCH,
      payload:
        response.data && Array.isArray(response.data) ? response.data : [],
    });
  } catch (error) {
    dispatch({
      type: BLOG_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchBlog = (id) => async (dispatch) => {
  try {
    dispatch({
      type: BLOG_REQUEST,
    });

    const response = await cfAPI.get(`${BaseRoute}/${id}`);

    dispatch({
      type: BLOG_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: BLOG_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UpdateBlog = (id, values) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BLOG_REQUEST,
    });

    const response = await cfAPI.patch(`${BaseRoute}/${id}`, values);

    dispatch({
      type: BLOG_EDIT,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: BLOG_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteBlog = (values) => async (dispatch) => {
  try {
    dispatch({
      type: BLOG_REQUEST,
    });

    const response = await cfAPI.delete(`${BaseRoute}?id=${values.id}`);

    dispatch({
      type: BLOG_DELETE,
      status: response.status,
      payload: values,
    });
  } catch (error) {
    dispatch({
      type: BLOG_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const BlogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BLOG_REQUEST:
      return { ...state, loading: true };
    case BLOGS_FETCH:
      return { ...state, loading: false, blogs: action.payload };
    case BLOG_FETCH:
      return { ...state, loading: false, blog: action.payload };
    case BLOG_CREATE:
      toast.success("Blog Created");
      return {
        ...state,
        loading: false,
      };
    case BLOG_EDIT:
      toast.success("Successful Update");

      return { ...state, loading: false, blog: action.payload };
    case BLOG_DELETE:
      toast.success("Blog Deleted");
      const newList = state.blogs.filter((s) => s.id !== action.payload.id);
      return {
        ...state,
        loading: false,
        blogs: newList,
      };
    case BLOG_ERROR:
      toast.error("Something Bad Happened");
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
