import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import { FilePond, registerPlugin } from "react-filepond";
import { useEffect, useRef } from "react";

import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";

// Register the plugins
registerPlugin(
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageCrop,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize,
  FilePondPluginImageEdit,
  FilePondPluginImagePreview
);

export const ImageUploadComponent = ({
  name,
  files,
  onupdatefiles,
  extraProp,
  maxUpload,
  onremoveImage,
  ...otherProps
}) => {
  const pond = useRef();

  useEffect(() => {
    // Load the avaar here?
    if (
      !pond.current ||
      !extraProp?.mediaFiles ||
      extraProp?.mediaFiles.length === 0
    )
      return;

    for (const file of extraProp?.mediaFiles) {
      pond.current.addFile(file.url);
    }
  }, [pond]);

  const onDeleteFile = (event, file) => {
    if (onremoveImage) {
      if (!pond.current) return;
      console.log(file);
      onremoveImage(extraProp.name, file.file.name);
    }
  };

  return (
    <>
      <FilePond
        {...otherProps}
        name={name}
        // files={files}
        onupdatefiles={onupdatefiles}
        onremovefile={onDeleteFile}
        ref={pond}
        allowReorder
        allowMultiple
        allowRevert
        maxFiles={maxUpload ? maxUpload : 30}
        allowReplace
        storeAsFile
        imageValidateSizeMinWidth={100}
        imageValidateSizeMaxWidth={5000}
        imageValidateSizeMinHeight={100}
        imageValidateSizeMaxHeight={5000}
        allowImagePreview
        imagePreviewMarkupShow
        acceptedFileTypes={["image/*", "*"]}
        allowImageCrop
        allowFileSizeValidation
        maxFileSize="20MB"
        labelIdle='<p>Drag & Drop or <span class="filepond--label-action">Browse</span></p>'
      />
    </>
  );
};
