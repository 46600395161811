import {
  CheckboxInput,
  FormContainer,
  Input,
  Label,
  SelectFinalForm,
  TransferListInput,
} from "../../components/inputs";
import { Field, Form } from "react-final-form";

import { Button } from "../../components/button";
import { Grid } from "../../components/section";
import { Text } from "../../components/typography";
import styled from "styled-components";

export const UserDetailForm = ({
  onSubmit,
  initialValues,
  permissions,
  onCloseandReset,
}) => {
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, pristine, submitting, invalid, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <Grid>
            <div>
              <Grid col="3">
                <Field
                  label="Username"
                  name="username"
                  type="text"
                  component={Input}
                  entity="&#10029;"
                />
                <Field
                  label="First Name"
                  name="firstName"
                  type="text"
                  component={Input}
                  entity="&#10029;"
                />
                <Field
                  label="Last Name"
                  name="lastName"
                  type="text"
                  component={Input}
                  entity="&#10029;"
                />
              </Grid>
              <div>
                <Field
                  name="email"
                  type="email"
                  component={Input}
                  label="Email"
                  entity="&#10029;"
                />
              </div>
              <Field
                name="role"
                label="Role"
                entity="&#10029;"
                component={SelectFinalForm}
                options={[
                  { label: "USER", value: "USER" },
                  {
                    label: "ADMIN",
                    value: "ADMIN",
                  },
                ]}
              />
              <Field
                name="enabled"
                type="checkbox"
                component={CheckboxInput}
                label="Activate"
                entity="&#10029;"
              />
            </div>
            {permissions && (
              <div>
                <Label>Permission Sets</Label>
                <div>
                  <FormContainer>
                    <Label text="User Permissions" />
                    <Field
                      name="permissions"
                      component={TransferListInput}
                      permission={permissions}
                    />
                  </FormContainer>
                </div>
              </div>
            )}
          </Grid>

          <ButtonFlex>
            <Text scale="small" onClick={() => onCloseandReset(reset)}>
              Cancel
            </Text>
            <Button
              type="submit"
              disabled={submitting || pristine || invalid}
              text="Enter Profile Details"
            >
              {submitting ? "Saving..." : "Save"}
            </Button>
          </ButtonFlex>
        </form>
      )}
    </Form>
  );
};

const ButtonFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a:link,
  p,
  button {
    margin: 0;
    cursor: pointer;
  }
`;
