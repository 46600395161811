import {
  SERVICES_FETCH,
  SERVICE_CREATE,
  SERVICE_DELETE,
  SERVICE_DELETE_IMAGE,
  SERVICE_EDIT,
  SERVICE_ERROR,
  SERVICE_FETCH,
  SERVICE_REQUEST,
} from "./ServiceTypes";

import { toast } from "react-toastify";

const STATE = {
  loading: false,
  services: [],
  service: {},
  error: "",
};

export const ServiceReducer = (state = STATE, action) => {
  switch (action.type) {
    case SERVICE_REQUEST:
      return { ...state, loading: true };
    case SERVICES_FETCH:
      return { ...state, loading: false, services: action.payload };
    case SERVICE_FETCH:
      return { ...state, loading: false, service: action.payload };
    case SERVICE_CREATE:
      toast.success("Service Created");
      return {
        ...state,
        loading: false,
        service: action.payload,
      };

    case SERVICE_EDIT:
      toast.success("Service Updated");
      return { ...state, loading: false, service: action.payload };
    case SERVICE_DELETE:
      toast.success("Service Deleted");
      return {
        ...state,
        loading: false,
      };
    case SERVICE_DELETE_IMAGE:
      toast.success("Image Deleted");
      return {
        ...state,
        loading: false,
        service: action.payload,
      };
    case SERVICE_ERROR:
      toast.error("Something Bad Happened");
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
