import "flatpickr/dist/themes/material_green.css";

import { BaseLabel, InputContainer } from "./Inputs";
import { Span, Text } from "../typography";

import Flatpickr from "react-flatpickr";
import { colors } from "../../assets/styles/parameters";
import moment from "moment";
import styled from "styled-components";

export const TimeInput = ({ name, ...props }) => {
  const date = Date.now();
  return (
    <InputContainer>
      <BaseLabel style={props.entity && { paddingLeft: "0.5rem" }} {...props}>
        <Text color={colors.gray700}>{props.label}</Text>
        {props.entity && <Span>{props.entity}</Span>}
      </BaseLabel>

      <BaseStyled
        options={{
          enableTime: true,
          noCalendar: true,
          dateFormat: "h:i",
        }}
        placeholder={moment(date).format("h:mm a")}
        {...props}
      />
    </InputContainer>
  );
};

export const BaseStyled = styled(Flatpickr)`
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 0.5px solid ${colors.gray400};
  border-radius: 2px;
  width: 100%;
  outline: none;
  padding: 0.26rem;

  &:is(:hover, :active, :focus) {
    border: 0.5px solid ${colors.black};
  }

  &:is(:visited, :active, :focus, :default) {
    background-color: transparent;
  }

  &::placeholder {
    font-size: 0.8rem;
    color: ${colors.gray400};
  }
`;
