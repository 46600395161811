import { useLocation } from "react-router-dom";

import { IoAddCircleOutline } from "react-icons/io5";
import { Layout } from "../components/section";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { PageHeader } from "../components/header";

export const About = () => {
  return (
    <>
      <PageHeader title="About" />
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export const Awards = () => {
  const location = useLocation();
  return (
    <>
      <PageHeader title="Awards">
        {location.pathname
          .split("/")
          .includes("create") ? null : !location.pathname
            .split("/")
            .includes("edit") ? (
          <Link to={`/about/awards/create`}>
            <IoAddCircleOutline />
          </Link>
        ) : null}
      </PageHeader>
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export const Experience = () => {
  const location = useLocation();
  return (
    <>
      <PageHeader title="Experiences">
        {location.pathname
          .split("/")
          .includes("create") ? null : !location.pathname
            .split("/")
            .includes("edit") ? (
          <Link to={`/about/experiences/create`}>
            <IoAddCircleOutline />
          </Link>
        ) : null}
      </PageHeader>
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};
