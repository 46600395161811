import styled from "styled-components";
import { Text } from "../typography";
import { colors } from "../../assets/styles/parameters";

export const RadioInput = ({ name }) => {
  return (
    <BaseLabel>
      <Text>{name}</Text>
      <input type="radio" name="radio" />
      <span></span>
    </BaseLabel>
  );
};

const BaseLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ span {
      background-color: ${colors.blue};
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: ${colors.gray400};
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }

  &:hover input ~ span {
    background-color: #ccc;
  }

  input:checked ~ span:after {
    display: block;
  }
`;
