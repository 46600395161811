import "react-toastify/dist/ReactToastify.css";

import CombinedRoutes from "./routes/CombinedRoutes";
import { GlobalStyles } from "./assets/styles/globalstyles";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <>
      <GlobalStyles />
      <CombinedRoutes />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
};

export default App;
