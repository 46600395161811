import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Title } from "../typography";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";
import { useState } from "react";

export const CustomDragNDrop = ({ ...otherProps }) => {
  const [taskList, setTasks] = useState([]);

  const onDragEnd = (results) => {
    const { destination, source, draggableId } = results;

    const startGroup = source?.droppableId;
    const destinationGroup = destination?.droppableId;

    if (!startGroup) {
      return;
    }

    if (!destinationGroup) {
      return;
    }

    const [movingTask] = taskList.filter((task) => task.id === draggableId);

    if (startGroup === destinationGroup) {
      const newTasks = Array.from(taskList);

      newTasks.splice(source.index, 1);
      newTasks.splice(destination.index, 0, movingTask);

      setTasks(newTasks);
      return;
    }
  };

  return (
    <>
      <BaseColumnContainer {...otherProps}>
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <PermissionColumnList
              id="AllPermissions"
              list={taskList}
              title="All Permissions"
            />
            <PermissionColumnList
              id="CurrentPermissions"
              list={taskList}
              title="Current Permissions"
            />
          </DragDropContext>
        </div>
      </BaseColumnContainer>
    </>
  );
};

const PermissionColumnList = ({ title, id, list }) => {
  return (
    <BaseColumnStyles>
      <PermissionColumnList>
        <Title number="6">{title}</Title>
        <Droppable droppableId={id}>
          {(provided) => (
            <BaseList ref={provided.innerRef} {...provided.droppableProps}>
              {list &&
                list?.map((s, index) => (
                  <ListItem id={s.id} key={s.id} title={s.name} index={index} />
                ))}
              {provided.placeholder}
            </BaseList>
          )}
        </Droppable>
      </PermissionColumnList>
    </BaseColumnStyles>
  );
};

const ListItem = (props) => {
  const { title, index, id } = props;
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <BaseColumnListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {title}
        </BaseColumnListItem>
      )}
    </Draggable>
  );
};

const BaseColumnContainer = styled.div`
  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
`;
const BaseColumnStyles = styled.div`
  background-color: ${colors.gray200};
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 0.5px solid ${colors.gray400};
  border-radius: 5px;
  width: 100%;
  outline: none;
  padding: 0.2rem 0.5rem;
  min-height: 100px;
`;

const BaseList = styled.ul`
  border-top: 1px solid red;
  overflow-y: scroll;
`;

const BaseColumnListItem = styled.li`
  border: 1px solid ${colors.gray300};
  padding: 0 1rem;
  background-color: ${colors.white};
  cursor: pointer;
`;
