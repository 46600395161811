import styled, { css } from "styled-components";

import { headings } from "../../assets/styles/parameters";

const Title = ({ number, fontsize, children, ...otherProps }) => {
  switch (number) {
    case "1":
      return (
        <First {...otherProps} fontsize={fontsize}>
          {children}
        </First>
      );
    case "2":
      return <Second {...otherProps}>{children}</Second>;
    case "3":
      return <Third {...otherProps}>{children}</Third>;
    case "4":
      return <Fourth {...otherProps}>{children}</Fourth>;
    case "5":
      return <Fifth {...otherProps}>{children}</Fifth>;
    case "6":
      return <Sixth {...otherProps}>{children}</Sixth>;
    default:
      return <Third {...otherProps}>{children}</Third>;
  }
};

export default Title;

const First = styled.h1`
  ${({ fontSize }) => {
    switch (fontSize) {
      case "small":
        return css`
          font-size: ${headings.h5};
        `;
      case "medium":
        return css`
          font-size: ${headings.h3};
        `;
      default:
        return css`
          font-size: ${headings.h1};
        `;
    }
  }};
`;

const Second = styled.h2`
  font-size: ${headings.h2};
`;

const Third = styled.h3`
  font-size: ${headings.h3};
`;

const Fourth = styled.h4`
  font-size: ${headings.h4};
`;

const Fifth = styled.h5`
  font-size: ${headings.h5};
`;

const Sixth = styled.h6`
  font-size: ${headings.h6};
`;
