import { Layout, Section } from "../../components/section";

import { CreateGallery } from "../../redux/gallery";
import { GalleryForm } from "./GalleryForm";
import { useDispatch } from "react-redux";

export const GalleryCreate = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save?`)) {
      dispatch(CreateGallery(values));
    }
  };

  return (
    <Layout>
      <Section>
        <GalleryForm onFormSubmit={onFormSubmit} />
      </Section>
    </Layout>
  );
};
