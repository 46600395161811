import { cfAPI } from "../api";
import { toast } from "react-toastify";

export const AWARD_REQUEST = "AWARD_REQUEST";
export const AWARD_ERROR = "AWARD_ERROR";
export const AWARDS_FETCH = "AWARDS_FETCH";
export const AWARD_FETCH = "AWARD_FETCH";
export const AWARD_CREATE = "AWARD_CREATE";
export const AWARD_EDIT = "AWARD_EDIT";
export const AWARD_DELETE = "AWARD_DELETE";

const INITIAL_STATE = {
  loading: false,
  awards: [],
  award: {},
  error: "",
};

const BaseRoute = "cfwebapi/api/v1/awards";

export const FetchAwards = () => async (dispatch) => {
  try {
    dispatch({
      type: AWARD_REQUEST,
    });

    const response = await cfAPI.get(BaseRoute);

    dispatch({
      type: AWARDS_FETCH,
      payload:
        response.data && Array.isArray(response.data) ? response.data : [],
    });
  } catch (error) {
    dispatch({
      type: AWARD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchAward = (id) => async (dispatch) => {
  try {
    dispatch({
      type: AWARD_REQUEST,
    });

    const response = await cfAPI.get(`${BaseRoute}/${id}`);

    dispatch({
      type: AWARD_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: AWARD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CreateAward = (values) => async (dispatch) => {
  try {
    dispatch({
      type: AWARD_REQUEST,
    });

    const response = await cfAPI.post(BaseRoute, values);

    dispatch({
      type: AWARD_CREATE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: AWARD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UpdateAward = (id, values) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AWARD_REQUEST,
    });

    const response = await cfAPI.patch(`${BaseRoute}/${id}`, values);

    dispatch({
      type: AWARD_EDIT,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: AWARD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteAward = (values) => async (dispatch) => {
  try {
    dispatch({
      type: AWARD_REQUEST,
    });

    const response = await cfAPI.delete(`${BaseRoute}?id=${values.id}`);

    dispatch({
      type: AWARD_DELETE,
      status: response.status,
      payload: values,
    });
  } catch (error) {
    dispatch({
      type: AWARD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const AwardsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AWARD_REQUEST:
      return { ...state, loading: true };
    case AWARDS_FETCH:
      return { ...state, loading: false, awards: action.payload };
    case AWARD_FETCH:
      return { ...state, loading: false, award: action.payload };
    case AWARD_CREATE:
      toast.success("Award Created");
      return {
        ...state,
        loading: false,
      };
    case AWARD_EDIT:
      return { ...state, loading: false, award: action.payload };
    case AWARD_DELETE:
      toast.success("Award Deleted");
      const newList = state.awards.filter((s) => s.id !== action.payload.id);
      return {
        ...state,
        loading: false,
        awards: newList,
      };
    case AWARD_ERROR:
      toast.error("Something Bad Happened");
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
