import { useDispatch, useSelector } from "react-redux";

import { Cards } from "../../components/cards";
import { FetchGalleries } from "../../redux/gallery";
import { Grid } from "../../components/section";
import styled from "styled-components";
import { useEffect } from "react";

export const Gallery = () => {
  const dispatch = useDispatch();
  const { galleries } = useSelector((state) => state.gallery);

  useEffect(() => {
    dispatch(FetchGalleries());
  }, [dispatch]);

  return (
    <div>
      <Container col="3">
        {galleries[0]?.media?.mediaFiles
          ?.filter((x, i) => x && i <= 5)
          .map((r) => {
            const name = r?.fileName?.split("~")[1]?.split(".")[0];
            const id = r?.fileName?.split("~")[0];
            return (
              <Cards
                version="4"
                key={id}
                image={r?.url}
                imageDescription={name}
              />
            );
          })}
      </Container>
    </div>
  );
};

export default Gallery;

const Container = styled(Grid)`
  gap: 0;

  img {
    height: 100px;
  }
`;
