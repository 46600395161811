import { Span, Text } from "../typography";
import { colors, responsiveness } from "../../assets/styles/parameters";

import styled from "styled-components";

export const Label = ({ children, text, entity, ...otherProps }) => {
  return (
    <BaseLabel style={entity && { paddingLeft: "0.5rem" }} {...otherProps}>
      <Text color={colors.gray700}>{text}</Text>
      {entity && <Span>{entity}</Span>}
    </BaseLabel>
  );
};

export const Input = (props) => {
  const { input, meta, children, label, entity, ...otherProps } = props;
  const { touched, valid, error } = meta;
  return (
    <InputContainer>
      {label && <Label text={label} entity={entity} />}
      <BaseInput {...input} {...otherProps}>
        {children}
      </BaseInput>
      {!valid && touched && error ? (
        <Span color="red" size="small">
          {error}
        </Span>
      ) : null}
    </InputContainer>
  );
};

export const CustomTextArea = (props) => {
  const { input, meta, children, label, entity, ...otherProps } = props;
  const { touched, valid, error } = meta;
  return (
    <InputContainer>
      {label && <Label text={label} entity={entity} />}
      <TextAreaInput as="textarea" {...input} {...otherProps}>
        {children}
      </TextAreaInput>
      {!valid && touched && error ? (
        <Span color="red" size="small">
          {error}
        </Span>
      ) : null}
    </InputContainer>
  );
};

export const CustomSelect = (props) => {
  const { input, meta, children, label, entity, ...otherProps } = props;
  const { touched, valid, error } = meta;
  return (
    <InputContainer>
      {label && <Label text={label} entity={entity} />}
      <BaseInput as="select" {...input} {...otherProps}>
        {children}
      </BaseInput>
      {!valid && touched && error ? (
        <Span color="red" size="small">
          {error}
        </Span>
      ) : null}
    </InputContainer>
  );
};

export const FormContainer = styled.div`
  margin-bottom: 2rem;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    margin-bottom: 2rem;
  }
`;

export const BaseInput = styled.input`
  background-color: ${colors.gray200};
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 0.5px solid ${colors.gray400};
  border-radius: 5px;
  width: 100%;
  outline: none;
  padding: 0.2rem 0.5rem;

  &:is(:hover, :active, :focus) {
    border: 0.5px solid ${colors.black};
  }

  &:is(:visited, :active, :focus, :default) {
    background-color: ${colors.white};
  }

  &::placeholder {
    font-size: 0.8rem;
    color: ${colors.gray400};
  }
`;

export const BaseLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: inherit;
  line-height: inherit;
  color: ${colors.black};

  & > p {
    margin: 0 !important;
    padding: 0;
  }

  & > span {
    position: absolute;
    top: -10;
    left: 0;
    font-size: 0.5rem;
    color: red;
    height: min-content;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    align-items: flex-start;
    text-align: left;
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 1rem;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    margin-bottom: 2rem;
  }
`;

export const CheckBoxFormContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  * {
    padding: 0 !important;
    margin: 0 !important;
  }

  p {
    margin-left: 0.5rem !important;
  }
`;

const TextAreaInput = styled(BaseInput)`
  height: 200px;
  max-height: 40rem;
  resize: vertical;
`;
