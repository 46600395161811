import { IoCloseOutline, IoExitSharp, IoStar } from "react-icons/io5";
import { ShowNav, UserLogout } from "../../redux/user/UserActions";
import { colors, responsiveness, shadow } from "../../assets/styles/parameters";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { SidebarPath } from "./Paths";
import { Text } from "../typography";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export const Sidebar = () => {
  const { open } = useSelector((state) => state.nav);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const onLogout = () => {
    navigation("/logout");
    dispatch(UserLogout());
  };

  return (
    <SideNav open={open}>
      <div>
        CF
        {open ? <IoCloseOutline onClick={() => dispatch(ShowNav)} /> : null}
      </div>
      <ListIcon>
        {SidebarPath.map((s) => {
          return (
            <li open={open} onClick={() => dispatch(ShowNav)}>
              <Link to={s.path} title={s.name}>
                {s.icon}
                <Text>{s.name}</Text>
              </Link>
            </li>
          );
        })}
      </ListIcon>
      <Logout>
        <IoExitSharp onClick={onLogout} />
      </Logout>
    </SideNav>
  );
};

export default Sidebar;

const SideNav = styled.nav`
  grid-area: sidebar;
  background: ${colors.blue};
  min-height: 99vh;
  width: 4%;
  position: fixed;
  left: 1%;
  top: 0.5%;
  border-radius: 5px;
  box-shadow: ${shadow.dark};
  z-index: 10000;
  padding: 0.6rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s ease-in;
  color: ${colors.white};

  img {
    width: 2rem;
  }

  div:first-child > svg {
    display: none;
  }

  p {
    display: none;
  }

  @media screen and (min-width: ${responsiveness.desktop1}) {
    &:hover {
      width: 15%;

      img {
        width: 4rem;
      }

      p {
        display: block;
        line-height: 0;
      }
    }
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    display: ${({ open }) => (open ? "flex" : "none")};
    width: ${({ open }) => (open ? "100%" : 0)};
    overflow-y: scroll;
    height: 100vh;
    left: 0;
    top: 0;
    border-radius: 0;
    z-index: 10000;
    padding-top: 1rem;

    img {
      width: 4rem;
    }

    div:first-child {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & > svg {
        display: ${({ open }) => (open ? "block" : "none")};
        color: ${colors.white};
        font-size: 2rem;
        cursor: pointer;
      }
    }

    p {
      display: block;
    }
  }
`;

const ListIcon = styled.ul`
  li {
    margin-top: 3rem;

    & > a {
      display: flex;
      align-items: center;

      svg,
      p {
        color: ${colors.white};
        margin-bottom: 0;
      }

      p {
        margin-left: 1rem;
      }
    }
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    li {
      font-size: 1.5rem;
      margin-top: 1.3rem;
    }
  }
`;

const Logout = styled.li`
  color: ${colors.white};
  display: flex;
  font-size: 1.2rem;
  cursor: pointer;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    font-size: 2rem;
  }
`;
