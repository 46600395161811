import {
  STAFFS_FETCH,
  STAFF_CREATE,
  STAFF_DELETE,
  STAFF_DELETE_IMAGE,
  STAFF_EDIT,
  STAFF_ERROR,
  STAFF_FETCH,
  STAFF_REQUEST,
} from "./StaffTypes";

import { toast } from "react-toastify";

const INITIAL_STATE = {
  loading: false,
  staffs: [],
  staff: {},
  error: "",
};

export const StaffsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STAFF_REQUEST:
      return { ...state, loading: true };

    case STAFFS_FETCH:
      return {
        ...state,
        loading: false,
        staffs: action.payload,
      };

    case STAFF_FETCH:
      return {
        ...state,
        loading: false,
        staff: action.payload,
      };

    case STAFF_CREATE:
      toast.success("Profile Created");
      return {
        ...state,
        loading: false,
        staff: action.payload,
      };

    case STAFF_EDIT:
      toast.success("Profile Updated");
      return {
        ...state,
        loading: false,
        staff: action.payload,
      };

    case STAFF_DELETE_IMAGE:
      toast.success("Image Deleted");
      return {
        ...state,
        loading: false,
        staff: action.payload,
      };

    case STAFF_DELETE:
      toast.success("Profile Deleted");
      return {
        ...state,
        loading: false,
      };

    case STAFF_ERROR:
      toast.error("Something Bad Happened");
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
