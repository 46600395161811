import _ from "lodash";
import { cfAPI } from "../api";
import { toast } from "react-toastify";

export const EXPERIENCE_REQUEST = "EXPERIENCE_REQUEST";
export const EXPERIENCE_ERROR = "EXPERIENCE_ERROR";
export const EXPERIENCES_FETCH = "EXPERIENCES_FETCH";
export const EXPERIENCE_FETCH = "EXPERIENCE_FETCH";
export const EXPERIENCE_CREATE = "EXPERIENCE_CREATE";
export const EXPERIENCE_EDIT = "EXPERIENCE_EDIT";
export const EXPERIENCE_DELETE = "EXPERIENCE_DELETE";

const INITIAL_STATE = {
  loading: false,
  exps: [],
  exp: {},
  error: "",
};

const BaseRoute = "cfwebapi/api/v1/projects";

export const FetchExperiences = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPERIENCE_REQUEST,
    });

    const response = await cfAPI.get(BaseRoute);

    dispatch({
      type: EXPERIENCES_FETCH,
      payload:
        response.data && Array.isArray(response.data) ? response.data : [],
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchExperience = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EXPERIENCE_REQUEST,
    });

    const response = await cfAPI.get(`${BaseRoute}/${id}`);

    dispatch({
      type: EXPERIENCE_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CreateExperience = (values) => async (dispatch) => {
  try {
    dispatch({
      type: EXPERIENCE_REQUEST,
    });

    const formData = new FormData();

    const finalData = _.omit(
      {
        ...values,
        description: values.description || " ",
        mediaType: "IMAGE",
      },
      "files"
    );

    if (values.files) {
      for (const file of values.files) {
        formData.append("files", file.file);
      }
    }

    const blob = new Blob([JSON.stringify(finalData)], {
      type: "application/json",
    });

    formData.append("data", blob);

    const response = await cfAPI.post(BaseRoute, formData);

    dispatch({
      type: EXPERIENCE_CREATE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UpdateExperience = (id, values) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXPERIENCE_REQUEST,
    });

    const formData = new FormData();

    const finalData = _.omit(
      {
        ...values,
        description: values.description || " ",
        mediaType: "IMAGE",
      },
      "files"
    );

    if (values.files) {
      for (const file of values.files) {
        formData.append("files", file.file);
      }
    }

    const blob = new Blob([JSON.stringify(finalData)], {
      type: "application/json",
    });

    formData.append("data", blob);

    const response = await cfAPI.patch(`${BaseRoute}/${id}`, formData);

    dispatch({
      type: EXPERIENCE_EDIT,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteExperience = (values) => async (dispatch) => {
  try {
    dispatch({
      type: EXPERIENCE_REQUEST,
    });

    const response = await cfAPI.delete(`${BaseRoute}/${values.id}`);

    dispatch({
      type: EXPERIENCE_DELETE,
      status: response.status,
      payload: values,
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ExperiencesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EXPERIENCE_REQUEST:
      return { ...state, loading: true };
    case EXPERIENCES_FETCH:
      return { ...state, loading: false, exps: action.payload };
    case EXPERIENCE_FETCH:
      return { ...state, loading: false, exp: action.payload };
    case EXPERIENCE_CREATE:
      toast.success("Experience Created");
      return {
        ...state,
        loading: false,
      };
    case EXPERIENCE_EDIT:
      toast.success("Experience Updated");
      return { ...state, loading: false, exp: action.payload };
    case EXPERIENCE_DELETE:
      toast.success("Experience Deleted");
      const newList = state.exps.filter((s) => s.id !== action.payload.id);
      return {
        ...state,
        loading: false,
        exps: newList,
      };
    case EXPERIENCE_ERROR:
      toast.error("Something Bad Happened");
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
