import { CreateEvent } from "../../redux/events";
import { EventForm } from "./EventForm";
import { Section } from "../../components/section";
import { useDispatch } from "react-redux";

export const EventCreate = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to add this article`)) {
      const address = {
        street: values.street || " ",
        city: values.city || " ",
        state: values.state || " ",
        zipcode: values.zipcode || " ",
      };

      const newValues = {
        ...values,
        description: values.description || " ",
        address,
      };

      dispatch(CreateEvent(newValues));
    }
  };

  return (
    <Section>
      <EventForm onFormSubmit={onFormSubmit} />
    </Section>
  );
};
