import { IoCheckboxOutline, IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "react-data-table-component";
import { FetchBlogs } from "../../redux/blog";
import { IoPencilOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Moment from "moment";
import { Section } from "../../components/section";
import styled from "styled-components";
import { useEffect } from "react";

export const BlogList = () => {
  const { blogs } = useSelector((state) => state.blogs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchBlogs());
  }, [dispatch]);

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Published Date",
      selector: ({ createdDate }) => {
        return Moment(createdDate).format("MMM DD, YYYY");
      },
      sortable: true,
      right: true,
      hide: "md",
    },
    {
      name: "Featured",
      sortable: true,
      center: true,
      selector: (row) => (
        <div>{row.featured ? <IoCheckboxOutline /> : <IoCloseSharp />}</div>
      ),
      hide: "md",
      width: "100px",
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <Link to={`/press/${row.id}/edit`} state={{ blog: row }}>
            <IoPencilOutline />
          </Link>
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      center: true,
      width: "100px",
      name: "Edit",
    },
  ];

  return (
    <Section>
      <DataTable
        columns={columns}
        data={blogs}
        striped
        persistTableHead
        pagination
        highlightOnHover
        compact
        fixedHeader
        responsive
      />
    </Section>
  );
};

const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;
