import { UserRefreshToken } from "../redux/user/UserActions";
import axios from "axios";

const BaseRoute = "/userapi/api/v1";

export const cfAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const Interceptor = (store) => {
  const { dispatch, getState } = store;

  cfAPI.interceptors.request.use(
    (config) => {
      const token = getState()?.users?.userInfo?.token;

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  cfAPI.interceptors.response.use(
    (res) => {
      return res;
    },

    async (err) => {
      const originalConfig = err.config;

      if (originalConfig?.url !== `${BaseRoute}/auth/signin` && err.response) {
        if (
          (err.response.status === 401 || err.response.status === 403) &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;

          const refreshToken = getState()?.users?.userInfo?.refreshtoken;
          try {
            if (refreshToken) {
              const response = await cfAPI.post(
                `${BaseRoute}/auth/refreshToken`,
                {
                  refreshToken: refreshToken,
                }
              );

              dispatch(UserRefreshToken(response.data));
              return cfAPI(originalConfig);
            }
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
    }
  );
};
