import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from "redux-persist";

import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { rootReducer } from "./rootReducer";
import sessionStorage from "redux-persist/lib/storage/session";

export const KEY = "293939fk";

const persistConfig = {
  key: KEY,
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2,
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const logger = createLogger({ duration: true });

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
});
