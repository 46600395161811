import { Text, Title } from "../components/typography";
import { useNavigate } from "react-router-dom";

import { colors } from "../assets/styles/parameters";
import styled from "styled-components";

export const ErrorPage = (props) => {
  const navigate = useNavigate();

  const onReturnToPreviousPage = () => {
    return navigate(-1, { replace: true });
  };

  return (
    <MainError>
      <div>
        <Title number={1}> Something bad happened!</Title>
        {props.message && (
          <Text onClick={onReturnToPreviousPage}>
            {props.message && props.message}
          </Text>
        )}
        <Text onClick={onReturnToPreviousPage}>Return to previous page.</Text>
      </div>
    </MainError>
  );
};

const MainError = styled.main`
  position: absolute;
  background-color: white;
  height: 100vh;
  width: 100%;
  z-index: 10000000;
  display: flex;
  align-items: center;

  div {
    width: 70%;
    margin: auto;
    text-align: center;

    p {
      color: ${colors.blue};
      cursor: pointer;
    }
  }

  * {
    margin: 0;
    padding: 0;
  }
`;
