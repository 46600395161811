import { Oval, ThreeDots } from "react-loader-spinner";

import { Title } from "../typography";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

export const Loader = ({ version }) => {
  switch (version) {
    case "1":
      return (
        <BaseComponentLoading>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color={colors.blue}
            ariaLabel="three-dots-loading"
            visible={true}
          />
        </BaseComponentLoading>
      );
    case "oval":
      return (
        <Oval
          height={80}
          width={80}
          color={colors.blue}
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor={colors.gold}
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      );
    default:
      return (
        <BaseLoaderSection>
          <Title number={1}>Corbin Ford</Title>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color={colors.gold}
            ariaLabel="three-dots-loading"
            visible={true}
          />
        </BaseLoaderSection>
      );
  }
};

const BaseFlex = `
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;
const BaseLoaderSection = styled.div`
  ${BaseFlex}
  position: absolute;
  background: ${colors.gray100};
  height: 100vh;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
`;

const BaseComponentLoading = styled.div`
  ${BaseFlex}
  height: 100%;
`;
