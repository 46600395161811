import { Link, useNavigate } from "react-router-dom";
import {
  colors,
  fontSize,
  responsiveness,
} from "../../assets/styles/parameters";
import { useDispatch, useSelector } from "react-redux";

import { ActionDropdown } from "../dropdown";
import { MainPaths } from "./Paths";
import NavigationLink from "./NavigationLink";
import { ShowNav } from "../../redux/user/UserActions";
import { Text } from "../typography";
import { UserLogout } from "../../redux/user/UserActions";
import img from "../../assets/imgs/cford.webp";
import styled from "styled-components";

export const Navigation = () => {
  const { open } = useSelector((state) => state.nav);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const onLogout = () => {
    navigation("/logout");
    dispatch(UserLogout());
  };

  return (
    <BaseNav
      navShow={open}
      className={
        open
          ? (window.document.body.style.overflow =
              "hidden" && window.document.body.style.scrollY === "hidden")
          : null
      }
    >
      <NavigationLink
        paths={MainPaths}
        navShow={open}
        onShowMobileNav={() => dispatch(ShowNav)}
      />
      <BaseUserNav navShow={open}>
        <ActionDropdown iconTitle={<img src={img} alt="" />} title="Account">
          <Link to="/settings/profile">Profile</Link>
          <Link to="/settings">Account & Settings</Link>

          <a
            href="https://corbinford.com"
            target="_blank"
            rel="noreferrer"
            onClick={() => dispatch(ShowNav)}
          >
            Go to website
          </a>
          <Text onClick={onLogout}>Log Out</Text>
        </ActionDropdown>

        <svg
          onClick={() => dispatch(ShowNav)}
          xmlns="http://www.w3.org/2000/svg"
          width="52"
          height="12"
          viewBox="0 0 52 12"
        >
          <g
            id="Group_520"
            data-name="Group 520"
            transform="translate(-189 -798)"
          >
            <line
              id="Line_49"
              data-name="Line 49"
              x2="34"
              transform="translate(206 799)"
              fill="none"
              stroke={colors.blue}
              strokeLinecap="round"
              strokeWidth="2"
            />
            <line
              id="Line_50"
              data-name="Line 50"
              x2="38"
              transform="translate(202 804)"
              fill="none"
              stroke={colors.blue}
              strokeLinecap="round"
              strokeWidth="2"
            />
            <line
              id="Line_51"
              data-name="Line 51"
              x2="50"
              transform="translate(190 809)"
              fill="none"
              stroke={colors.blue}
              strokeLinecap="round"
              strokeWidth="2"
            />
          </g>
        </svg>
      </BaseUserNav>
    </BaseNav>
  );
};

export default Navigation;

const BaseNav = styled.nav`
  grid-area: navigation;
  font-size: ${fontSize.nav};
  height: min-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  width: 100%;
  align-items: center;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    flex-direction: column-reverse;
    padding: 0 0.6rem;
  }
`;

const BaseUserNav = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  img {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    object-fit: cover;
  }

  div > p:last-child {
    &:hover {
      cursor: pointer;
    }
  }

  svg {
    display: none;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    /* justify-content: space-between; */

    svg {
      display: ${({ ShowNav }) => (ShowNav ? "none" : "block")};
      align-self: center;
      font-size: 5rem;
      margin-left: 2rem;
    }
  }
`;
