import { colors, responsiveness } from "../../assets/styles/parameters";

import NavLinkItem from "./NavigationItem";
import styled from "styled-components";

const NavigationLink = ({ paths, navShow, onShowMobileNav, ...otherProps }) => {
  return (
    <NavLinkList navShow={navShow} {...otherProps}>
      {paths.map((s) => {
        return (
          <NavLinkItem name={s.name} path={s.path} onClick={onShowMobileNav} />
        );
      })}
    </NavLinkList>
  );
};

export default NavigationLink;

const NavLinkList = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid transparent;

  & > svg {
    display: none;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    display: none;
    flex-direction: column;
    background-color: ${colors.white};
    z-index: 10000;
    height: 100vh;
    padding-bottom: 3rem;
    align-items: center;
    border-top: 1px solid black;
    justify-content: flex-start;

    svg {
      display: block;
      margin-right: 1rem;
      font-size: 1.5rem;
      fill: red;
    }
  }
`;
