import { Button } from "../../components/button";
import { Title } from "../../components/typography";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

export const UserProfile = ({ image, path, pathText, title }) => {
  return (
    <BaseUserCard
      version="1"
      title="User"
      href="/messages"
      hrefText="View Messages"
    >
      <img src={image} alt="" />

      <Title number="5">{title}</Title>

      <Button version="link" text={pathText} path={path} />
    </BaseUserCard>
  );
};

export default UserProfile;

const BaseUserCard = styled.div`
  padding: 1rem;
  min-height: 300px;
  height: 100%;
  border-radius: 5px;
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: ${colors.blue} no-repeat;
  background-size: cover;
  background-position: 250px -250px;

  & > img {
    clip-path: circle();
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  h5 {
    color: ${colors.white};
  }
`;
