import { DeleteAward, UpdateAward } from "../../redux/awards";

import { AwardsForm } from "./AwardsForm";
import { IoTrash } from "react-icons/io5";
import { Section } from "../../components/section";
import { Text } from "../../components/typography";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export const AwardsEdit = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save your edits?`)) {
      dispatch(UpdateAward(state.awards.id, values));
    }
  };

  const onDeleteSubmit = () => {
    if (window.confirm(`Are you sure you want to delete this?`)) {
      dispatch(DeleteAward(state.awards));
    }
  };

  const onHandleImageDelete = (mediaSource, fileName) => {};

  return (
    <Section>
      <SubNav>
        <li onClick={onDeleteSubmit}>
          <IoTrash />
          <Text>Delete</Text>
        </li>
      </SubNav>
      <AwardsForm
        initialValues={state.awards}
        onFormSubmit={onFormSubmit}
        onHandleImageDelete={onHandleImageDelete}
      />
    </Section>
  );
};

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    color: red;

    p {
      margin: 0;
      padding: 0 0 0 0.5rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
