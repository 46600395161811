import {
  FetchAccount,
  FetchAccountPermission,
  UpdateUserAccount,
} from "../../redux/accounts";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Section } from "../../components/section";
import { UserDetailForm } from "./UserDetailForm";
import { useParams } from "react-router-dom";

export const UserAccountFormDetail = () => {
  const { account, permissions } = useSelector((state) => state.accounts);

  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(FetchAccount(params?.userName));
    dispatch(FetchAccountPermission());
  }, [dispatch, params?.userName]);

  const onCloseandReset = (reset) => {
    reset();

    const direction = location?.pathname?.split("/")?.includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/settings", { replace: true });
    return direction;
  };

  const onSubmit = (values) => {
    dispatch(
      UpdateUserAccount(account.id, {
        firstName: values.firstName || "",
        lastName: values.lastName || "",
        userName: values.username || "",
        email: values.email || "",
        enabled: values.enabled || false,
        permissions:
          values?.permissions[1]?.map((i) => ({
            id: i.id,
            description: i.description,
            name: i.value,
          })) || [],
        role: values.role.value,
      })
    );
  };

  const newAccount = {
    ...account,
    permissions:
      account?.permissions?.map((i) => ({
        id: i.id,
        value: i.name,
        label: i.name,
        description: i.description,
      })) || [],
    role: { label: account?.role, value: account?.role },
  };

  const memoPermissions = useMemo(() => {
    if (!permissions || !account) return [];
    if (permissions?.length === 0) return [];
    if (account?.permissions?.length === 0) return [];
    return (
      permissions?.map((i) => ({
        id: i.id,
        label: i.name,
        value: i.name,
        description: i.description,
      })) || []
    );
  }, [permissions, account]);

  return (
    <Section>
      <UserDetailForm
        onSubmit={onSubmit}
        initialValues={newAccount}
        permissions={memoPermissions}
        onCloseandReset={onCloseandReset}
      />
    </Section>
  );
};
