import { BaseLabel, InputContainer } from "./Inputs";

import Currency from "react-currency-input-field";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

export const CurrencyInput = ({ label, input, ...otherProps }) => {
  return (
    <InputContainer>
      <BaseLabel {...otherProps}>{label}</BaseLabel>
      <BaseStyled
        id="input-example"
        placeholder="Please enter a price $1K, $1000, or $1000.34"
        defaultValue={0}
        decimalsLimit={2}
        onValueChange={(value) => input.onChange(value)}
        allowNegativeValue={false}
        value={input.value}
        {...otherProps}
      />
    </InputContainer>
  );
};

export const BaseStyled = styled(Currency)`
  background-color: ${colors.gray200};
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 0.5px solid ${colors.gray500};
  border-radius: 5px;
  width: 100%;
  outline: none;
  padding: 0.2rem 0.5rem;

  &:is(:hover, :active, :focus) {
    border: 0.5px solid ${colors.black};
  }

  &:is(:visited, :active, :focus, :default) {
    background-color: ${colors.white};
  }

  &::placeholder {
    font-size: 0.8rem;
    color: ${colors.gray600};
  }
`;
