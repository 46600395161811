import {
  NAV_OPEN,
  RESET_STATE,
  USER_ERROR,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_REQUEST_FAIL,
  USER_LOGIN_SUCCESS,
  USER_PROFILE_EDIT,
  USER_REFRESH_TOKEN,
  USER_SIGN_UP_CONFIRMATION,
  USER_SIGN_UP_FAIL,
  USER_SIGN_UP_SUCCESS,
} from "./UserTypes";

import { toast } from "react-toastify";

export const UserReducer = (
  state = {
    loading: true,
    userInfo: {},
    error: "",
  },
  action
) => {
  switch (action.type) {
    // case USER_REQUEST || USER_SIGN_UP_REQUEST:
    //   return { loading: true };
    case USER_LOGIN_SUCCESS:
      toast.success(`Welcome ${action.payload.user.firstName}`, {
        autoClose: 10000,
      });

      return {
        ...state,
        loading: false,
        status: action.status,
        userInfo: action.payload,
      };

    case USER_SIGN_UP_FAIL:
      return { ...state, loading: false, error: action.payload };
    case USER_SIGN_UP_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_SIGN_UP_CONFIRMATION:
      return { loading: false, userInfo: action.payload };
    case USER_REFRESH_TOKEN:
      return {
        ...state,
        userInfo: { ...state.userInfo, token: action.payload },
      };
    case USER_PROFILE_EDIT:
      toast.success("Profile Updated", {
        autoClose: 10000,
      });

      return {
        ...state,
        loading: false,
        userInfo: { ...state.userInfo, user: action.payload },
      };

    // case USER_FORGOT_PASSWORD_REQUEST:
    //   return { ...state, loading: false, passwordReset: action.payload };
    // case USER_PROFILE_LOGOUT:
    //   return {};
    case USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        userInfo: {},
      };
    default:
      return state;
  }
};

export const UserPassword = (
  state = {
    loading: true,
    passwordReset: "failure",
    status: "failure",
    error: "",
  },
  action
) => {
  switch (action.type) {
    case USER_FORGOT_PASSWORD_REQUEST:
      return { ...state, loading: false, passwordReset: action.payload };
    case USER_FORGOT_PASSWORD_REQUEST_FAIL:
      toast.error("Something Bad Happened");
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_STATE:
      return { ...state, loading: false, passwordReset: "failure" };
    default:
      return state;
  }
};

export const NavReducer = (
  state = {
    open: false,
  },
  action
) => {
  switch (action.type) {
    case NAV_OPEN:
      return { ...state, open: !state.open };
    default:
      return state;
  }
};
