import { ForgotPasswordForm, LoginForm } from "../elements/login";
import { Text, Title } from "../components/typography";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { PassResetState } from "../redux/user/UserActions";
import { debounce } from "../utils/misc";
import { responsiveness } from "../assets/styles/parameters";
import styled from "styled-components";
import { toast } from "react-toastify";

export const Login = () => {
  const [passReq, setPassReq] = useState(false);
  const { error } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const onShowResetForm = () => {
    dispatch(PassResetState);
    setPassReq(!passReq);
  };
  const showAlert = debounce(() => {
    toast.warning("Logged out because of inactivity");
    window.history.replaceState({}, document.title, "/");
  }, 1000);

  useEffect(() => {
    // This gets the entire string for URL parameters
    const queryString = window.location.search;
    // This parses the parameters, and now they're in a Set
    const urlParams = new URLSearchParams(queryString);
    // Get loggedout
    const loggedout = urlParams.get("loggedout");

    if (loggedout) showAlert();
  }, [showAlert]);

  return (
    <BaseHeader>
      <div>
        <Title>Corbin Ford</Title>
        {!passReq ? (
          <LoginForm setPassReq={onShowResetForm} error={error} />
        ) : (
          <ForgotPasswordForm setPassReq={onShowResetForm} />
        )}
        <BaseFooterSub scale="sm">
          &copy; Copyright
          <span className="year">{new Date().getFullYear()} </span>Corbin Ford -
          All Rights Reserved
        </BaseFooterSub>
      </div>
    </BaseHeader>
  );
};

const BaseHeader = styled.header`
  height: 100dvh;
  display: flex;
  justify-content: center;

  & > div {
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    align-items: center;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
    grid-auto-flow: dense;

    div:first-child {
      padding: 1rem;
      width: 100%;
    }
  }
`;

const BaseFooterSub = styled(Text)`
  font-size: 0.5rem;
  padding: 0;
  margin: 4rem 0 0 0;
  text-align: center;
`;
