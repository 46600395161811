import {
  CheckboxInput,
  CustomTextArea,
  FormContainer,
  Input,
  Label,
} from "../../components/inputs";
import { Field, Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "../../components/button";
import { Grid } from "../../components/section";
import { ImageUploadComponent } from "../../components/imageUpload";
import { Text } from "../../components/typography";
import arrayMutators from "final-form-arrays";
import styled from "styled-components";

export const GalleryForm = (props) => {
  const { onFormSubmit, initialValues, onHandleImageDelete } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/gallery", { replace: true });
    return direction;
  };

  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({
        handleSubmit,
        pristine,
        submitting,
        invalid,
        form: {
          mutators: { push },
          reset,
        },
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid col="l15">
            <div>
              <FormContainer>
                <Label text="Upload Gallery Images" />
                <Field name="files">
                  {(props) => {
                    return (
                      <ImageUploadComponent
                        name={props.input.name}
                        files={props.input.value}
                        onupdatefiles={props.input.onChange}
                        onremoveImage={onHandleImageDelete}
                        extraProp={initialValues?.media}
                      />
                    );
                  }}
                </Field>
              </FormContainer>
            </div>
            <div>
              <FormContainer>
                <Field
                  label="Title"
                  name="title"
                  type="text"
                  component={Input}
                  entity="&#10029;"
                  required
                />
              </FormContainer>
              <FormContainer>
                <Field
                  name="description"
                  type="text"
                  component={CustomTextArea}
                  label="Description"
                />
              </FormContainer>
              <FormContainer>
                <Field
                  name="videoUrl"
                  type="url"
                  component={Input}
                  label="Video URL"
                />
              </FormContainer>
              <FormContainer>
                <Field
                  label="Featured on Website"
                  name="featured"
                  type="checkbox"
                  component={CheckboxInput}
                />
              </FormContainer>{" "}
              <ButtonFlex>
                <Text scale="small" onClick={() => onCloseandReset(reset)}>
                  Cancel
                </Text>
                <Button
                  type="submit"
                  disabled={submitting || pristine || invalid}
                  text={submitting ? "Saving..." : "Save"}
                />
              </ButtonFlex>
            </div>
          </Grid>
        </form>
      )}
    </Form>
  );
};

const ButtonFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a:link,
  p,
  button {
    margin: 0;
    cursor: pointer;
  }
`;
