import { IoCheckboxOutline, IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "react-data-table-component";
import { FetchEvents } from "../../redux/events";
import { IoPencilOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Moment from "moment";
import { Section } from "../../components/section";
import styled from "styled-components";
import { useEffect } from "react";

export const EventList = () => {
  const { events } = useSelector((state) => state.events);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchEvents());
  }, [dispatch]);

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Event Date",
      selector: (row) => {
        return Moment(row.dateTime).format("MMM DD, YYYY");
      },
      sortable: true,
      width: "150px",
      hide: "md",
    },
    {
      name: "Event Time",
      selector: (row) => {
        return Moment(row.dateTime).format("hh:mm a");
      },
      sortable: true,
      width: "150px",
      hide: "md",
    },
    {
      name: "City, State",
      selector: (row) => `${row?.address?.city}, ${row?.address?.state}`,
      sortable: true,
      right: true,
      hide: "md",
    },
    {
      name: "Featured",
      sortable: true,
      center: true,
      selector: (row) => (
        <div>{row.featured ? <IoCheckboxOutline /> : <IoCloseSharp />}</div>
      ),
      hide: "md",
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <Link to={`/events/${row.id}/edit`} state={{ event: row }}>
            <IoPencilOutline />
          </Link>
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <Section>
      <DataTable
        columns={columns}
        data={events}
        striped
        persistTableHead
        pagination
        highlightOnHover
        compact
        fixedHeader
        responsive
      />
    </Section>
  );
};

const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;
