import { Title } from "../typography";
import { responsiveness } from "../../assets/styles/parameters";
import styled from "styled-components";

export const PageHeader = ({ title, children, ...otherProps }) => {
  return (
    <Wrapper {...otherProps}>
      <Title number="1" fontSize="small">
        {title}
      </Title>
      <PageNav>{children}</PageNav>
    </Wrapper>
  );
};

export default PageHeader;

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: header;
  padding: 0 2rem 0;
  margin: 1rem 0 2rem 0;
  width: 100%;
  line-height: 1;

  h1 {
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    margin: 2rem 0 3rem;
    padding: 0 0.5rem;

    svg {
      margin: 0;
      padding: 0;
    }
  }
`;

const PageNav = styled.nav`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > a,
  & > p,
  svg {
    margin: 0.9rem 0 0 1.2rem;
    padding: 0;
    font-size: 1.2rem;
    white-space: nowrap;
  }

  svg {
    color: green;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    align-items: flex-start;
  }
`;
