import { AnimatePresence, motion } from "framer-motion";
import { Text, Title } from "../typography";
import {
  colors,
  fontSize,
  responsiveness,
  shadow,
} from "../../assets/styles/parameters";

import styled from "styled-components";

export const PopUpRight = ({
  title,
  children,
  open,
  footer,
  onToggleOpenClose,
  ...otherProps
}) => {
  // Pass the open state and onToggleOpenClose function from parent component
  // Open = boolean state  const [open, setOpen] = useState(false)
  // onToggleOpenCLose - function to get the open state

  const variants = {
    open: {
      display: "block",
      opacity: 1,
      right: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
    closed: {
      display: "none",
      opacity: 0,
      right: -100,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  const overlayVariants = {
    open: {
      display: "block",
      opacity: 0.1,
      transition: { duration: 0.4, ease: "easeInOut" },
    },
    closed: {
      display: "none",
      opacity: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  return (
    <AnimatePresence initial={false}>
      {open && (
        <>
          <Overlay
            variants={overlayVariants}
            animate="open"
            initial="closed"
            exit="closed"
            onClick={onToggleOpenClose}
          />
          <PopUp
            // positionTransition
            variants={variants}
            animate="open"
            initial="closed"
            exit="closed"
            {...otherProps}
          >
            <PopUpHeader>
              <Title number="6">{title}</Title>
              <Text onClick={onToggleOpenClose}>Close</Text>
            </PopUpHeader>
            <PopUpContent>{children}</PopUpContent>
            {footer && <PopUpFooter>{footer}</PopUpFooter>}
          </PopUp>
        </>
      )}
    </AnimatePresence>
  );
};

export default PopUpRight;

const Overlay = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  background: ${colors.gray600};
  height: 100vh;
  width: 100%;
  z-index: 10000;
`;

const PopUp = styled(motion.div)`
  position: fixed;
  border-radius: 5px 0 0 5px;
  padding: 1.3rem 0;
  background-color: ${colors.white};
  top: 2.5%;
  width: ${({ width }) => (width ? width : "30%")};
  height: 95%;
  box-shadow: ${shadow.dark};
  z-index: 11000;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    width: 100%;
  }
`;

const PopUpHeader = styled.header`
  display: flex;
  padding: 0 1.5rem 0.5rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.gray200};

  h6,
  p {
    padding: 0;
    margin: 0;
    width: max-content;
  }

  p {
    font-size: ${fontSize.nav};

    &:hover {
      cursor: pointer;
      color: red;
    }
  }
`;

const PopUpContent = styled.div`
  padding: 1.5rem;
  overflow-y: scroll;
  height: 90%;
`;

const PopUpFooter = styled.footer`
  display: flex;
  padding: 0 1.5rem 0.5rem;
  margin: 0;
  justify-content: space-between;

  p {
    width: max-content;
    margin: 0;

    &:hover {
      cursor: pointer;
      color: red;
    }
  }
`;
