import {
  ContactDataTableExpandable,
  RowActionsContainer,
} from "./ContactExpandable";
import { DeleteContact, EditContact, FetchContacts } from "../../redux/contact";
import {
  IoMailOpenOutline,
  IoMailOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "react-data-table-component";
import Moment from "moment";
import { Section } from "../../components/section";
import { Status } from "../../components/badges";
import { useEffect } from "react";

export const ContactList = () => {
  const { contacts } = useSelector((state) => state.contacts);
  const dispatch = useDispatch();

  const onUpdateReadStatus = (value, read) => {
    if (read) {
      const newValue = {
        ...value,
        metadata: { ...value.metadata, messageRead: true },
      };
      dispatch(EditContact(newValue));
    } else {
      const messageRead = !value.metadata.messageRead;
      const newValue = {
        ...value,
        metadata: { ...value.metadata, messageRead },
      };
      dispatch(EditContact(newValue));
    }
  };

  const onDeleteWarning = (value) => {
    if (window.confirm("Are you sure you want to delete this message?")) {
      dispatch(DeleteContact(value));
    }
  };

  useEffect(() => {
    dispatch(FetchContacts());
  }, [dispatch]);

  const columns = [
    {
      name: "Status",
      selector: ({ metadata }) => metadata.messageRead,
      cell: (row) => (
        <Status status={row.metadata.messageRead ? "Read" : "Unread"} />
      ),
      allowOverflow: true,
      button: true,
      sortable: true,
      width: "56px",
    },
    {
      id: "date",
      name: "Contacted Date",
      selector: ({ createdDate }) => {
        return Moment(createdDate).format("MMM DD, YYYY");
      },
      sortable: true,
      reorder: true,
      hide: "sm",
    },
    {
      id: "createdTime",
      name: "Contacted Time",
      selector: ({ createdDate }) => Moment(createdDate).format("h:mm a"),
      sortable: true,
      reorder: true,
      hide: "md",
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
      reorder: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
      reorder: true,
      hide: "sm",
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      reorder: true,
      hide: "sm",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      reorder: true,
      hide: "md",
    },

    {
      cell: (row) => (
        <>
          <RowActionsContainer onClick={() => onUpdateReadStatus(row)}>
            {row.metadata.messageRead ? (
              <IoMailOpenOutline />
            ) : (
              <IoMailOutline />
            )}
          </RowActionsContainer>
          <RowActionsContainer>
            <IoTrashOutline onClick={() => onDeleteWarning(row)} />
          </RowActionsContainer>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
      name: "Actions",
    },
  ];

  return (
    <Section>
      <DataTable
        columns={columns}
        data={contacts}
        expandableRows
        expandableRowsComponent={({ data }) => (
          <ContactDataTableExpandable
            data={data}
            onUpdateReadStatus={onUpdateReadStatus}
          />
        )}
        striped
        persistTableHead
        pagination
        highlightOnHover
        compact
        fixedHeader
        responsive
      />
    </Section>
  );
};

export default ContactList;
