import { IoCheckboxOutline, IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "react-data-table-component";
import { FetchAwards } from "../../redux/awards";
import { IoPencilOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Section } from "../../components/section";
import styled from "styled-components";
import { useEffect } from "react";

export const AwardsList = () => {
  const { awards } = useSelector((state) => state.awards);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchAwards());
  }, [dispatch]);

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Featured",
      sortable: true,
      center: true,
      selector: (row) => (
        <div>{row.featured ? <IoCheckboxOutline /> : <IoCloseSharp />}</div>
      ),
      hide: "md",
      width: "100px",
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <Link to={`/about/awards/${row.id}/edit`} state={{ awards: row }}>
            <IoPencilOutline />
          </Link>
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      center: true,
      width: "100px",
      name: "Edit",
    },
  ];

  return (
    <Section>
      <DataTable
        columns={columns}
        data={awards}
        striped
        persistTableHead
        pagination
        highlightOnHover
        compact
        fixedHeader
        responsive
      />
    </Section>
  );
};

const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;
