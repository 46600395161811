import { colors, responsiveness } from "../../assets/styles/parameters";
import { useDispatch, useSelector } from "react-redux";

import { Cards } from "../../components/cards";
import { FetchContacts } from "../../redux/contact";
import Moment from "moment";
import { Status } from "../../components/badges";
import { Text } from "../../components/typography";
import styled from "styled-components";
import { useEffect } from "react";

export const MessageList = ({
  version,
  img,
  imgDes,
  text,
  status,
  path,
  children,
  ...otherProps
}) => {
  const { contacts } = useSelector((state) => state.contacts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchContacts());
  }, [dispatch]);

  const filteredMessages = contacts?.filter(
    (s) => s && s.metadata?.messageRead === false
  );

  return (
    <BaseMessageList
      version="1"
      title={`Messages - ${filteredMessages.length} Unread`}
      href="/messages"
      hrefText="View Messages"
    >
      {filteredMessages
        .filter((s, i) => i <= 4)
        .map((s) => {
          return (
            <BaseListItem {...otherProps} key={s.id}>
              <Text>{`${s.firstName}, ${Moment(s.createdDate).format(
                "MMM DD, YYYY"
              )}`}</Text>
              <Status status="Unread" />
            </BaseListItem>
          );
        })}
    </BaseMessageList>
  );
};

export default MessageList;

const BaseMessageList = styled(Cards)`
  & > ul {
    padding: 0.4rem 0 0;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    display: none;
  }
`;

const BaseListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gray200};
  padding: 0 1rem;

  p {
    margin: 0;
    padding: 0;
  }
`;
