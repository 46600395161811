import { Field, Form } from "react-final-form";
import {
  PasswordReset,
  PasswordResetToken,
} from "../../redux/user/UserActions";
import { Text, Title } from "../../components/typography";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../components/button";
import { Input } from "../../components/inputs";
import styled from "styled-components";
import { useState } from "react";

export const ForgotPasswordForm = (props) => {
  const [timer, setTimer] = useState(false);
  const dispatch = useDispatch();
  const { passwordReset } = useSelector((state) => state.pass);

  const onSubmitValues = (values) => {
    if (passwordReset === "success") {
      dispatch(PasswordResetToken(values));
      clearTimeout();
      setTimeout(() => {
        setTimer(true);
      }, 5000);
    } else {
      dispatch(PasswordReset(values));
    }
  };

  console.log(timer);
  return (
    <Form
      initialValues={props.initialValues}
      onSubmit={onSubmitValues}
      validate={(values) => {
        const errors = {};

        if (!values.email) {
          errors.email = "You must enter an email to reset password.";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }
        if (!values.password) {
          errors.password = "You must enter your password.";
        }
        if (values.password !== values.confirmPassword) {
          errors.password = "Password must match.";
        }

        return errors;
      }}
    >
      {({ handleSubmit, pristine, submitting, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Title number={11}>Reset your password</Title>
          <Text scale="small">
            Enter your email, and we’ll send you instructions on how to reset
            your password.
          </Text>
          <Field
            label="Email"
            name="email"
            type="email"
            component={Input}
            autoComplete="on"
          />
          <br />

          {timer ? (
            <>
              <Text scale="small">Enter your code and new password</Text>
              <Field label="Token" name="token" type="text" component={Input} />
              <Field
                label="New Password"
                name="password"
                type="password"
                component={Input}
                autoComplete="on"
              />
              <Field
                label="Confirm New Password"
                name="confirmPassword"
                type="password"
                component={Input}
                autoComplete="on"
              />
            </>
          ) : null}

          <InfoFlex>
            <Text
              scale="small"
              color="gray"
              onClick={() => props.setPassReq()}
              style={{ cursor: "pointer" }}
            >
              Return
            </Text>
            <Button
              type="submit"
              disabled={submitting || pristine || invalid}
              text="Submit Request"
            >
              {submitting ? "Saving....." : "Save"}
            </Button>
          </InfoFlex>
        </form>
      )}
    </Form>
  );
};

export default ForgotPasswordForm;

const InfoFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  p {
    margin: 0;
    width: fit-content;
  }
`;
