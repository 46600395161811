import { FetchAccountPermission, FetchAccounts } from "../../redux/accounts";
import { IoCheckboxOutline, IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../components/button";
import DataTable from "react-data-table-component";
import { IoPencilOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Section } from "../../components/section";
import { Title } from "../../components/typography";
import { colors } from "../../assets/styles/parameters";
import logo from "../../assets/imgs/cford.webp";
import portrait from "../../assets/imgs/cford.webp";
import styled from "styled-components";
import { useEffect } from "react";

export const UsersCard = () => {
  return (
    <BaseUserCard
      version="1"
      title="Accounts"
      href="/messages"
      hrefText="View Messages"
    >
      <Title number="5">Accounts</Title>

      <BaseImageContainer>
        <img src={portrait} alt="" />
        <img src={portrait} alt="" />
        <img src={portrait} alt="" />
      </BaseImageContainer>

      <Button
        version="link"
        varient="outlineWH"
        text="View Users"
        path="/settings/users"
      />
    </BaseUserCard>
  );
};

export const UsersList = () => {
  const { accounts, permissions } = useSelector((state) => state.accounts);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchAccounts());
    dispatch(FetchAccountPermission());
  }, [dispatch]);

  const columns = [
    {
      name: "Accounts",
      sortable: true,
      selector: (row) => (
        <Link to={`/settings/users/${row.username}/edit`} state={{ user: row }}>
          {row.firstName} {row.lastName}
        </Link>
      ),
    },
    {
      name: "Username",
      sortable: true,
      selector: (row) => row.username,
    },
    {
      name: "Email",
      sortable: true,
      selector: (row) => row.email,
      hide: "sm",
    },
    {
      name: "Role",
      sortable: true,
      selector: (row) => row.role,
      hide: "sm",
    },
    {
      name: "Permission Counts",
      sortable: true,
      selector: (row) => (
        <div>
          {row?.permissions?.length}/{permissions?.length}
        </div>
      ),
      hide: "sm",
    },
    {
      name: "Active",
      sortable: true,
      center: true,
      selector: (row) => (
        <div>{row.enabled ? <IoCheckboxOutline /> : <IoCloseSharp />}</div>
      ),
      hide: "md",
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <Link
            to={`/settings/users/${row.username}/edit`}
            state={{ user: row }}
          >
            <IoPencilOutline />
          </Link>
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];
  return (
    <>
      <Section>
        <DataTable
          columns={columns}
          data={accounts}
          persistTableHead="true"
          pagination
          highlightOnHover
          compact
          fixedHeader
          defaultSortAsc={true}
          defaultSortFieldId={(accounts) => accounts.role}
          responsive={true}
        />
      </Section>
    </>
  );
};

const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;

const BaseUserCard = styled.div`
  padding: 1rem;
  min-height: 300px;
  border-radius: 5px;
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  background: ${colors.black} url(${logo}) no-repeat;
  background-size: cover;
  background-position: 250px -250px;
  h5 {
    color: ${colors.white};
  }
`;

const BaseImageContainer = styled.div`
  position: relative;

  img {
    clip-path: circle();
    width: 100px;
    height: 100px;
    object-fit: cover;
    /* position: absolute; */
    top: 25%;

    &:nth-child(1) {
      left: 60%;
    }
    &:nth-child(2) {
      left: 40%;
    }
    &:nth-child(3) {
      left: 25%;
    }
  }
`;
