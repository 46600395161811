import { useLocation, useParams } from "react-router-dom";

import { IoAddCircleOutline } from "react-icons/io5";
import { Layout } from "../components/section";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { PageHeader } from "../components/header";

export const Blog = () => {
  const location = useLocation();
  const params = useParams();

  return (
    <>
      <PageHeader title="Press & Announcement">
        {!location.pathname.split("/").includes("create") && !params.id ? (
          <Link to={`/press/create`}>
            <IoAddCircleOutline />
          </Link>
        ) : null}{" "}
      </PageHeader>
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export default Blog;
