import { useDispatch, useSelector } from "react-redux";

import { Cards } from "../../components/cards";
import { FetchExperiences } from "../../redux/experience";
import { Text } from "../../components/typography";
import { colors } from "../../assets/styles/parameters";
import { responsiveness } from "../../assets/styles/parameters";
import styled from "styled-components";
import { useEffect } from "react";

export const ExperienceList = () => {
  const { exps } = useSelector((state) => state.exps);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchExperiences());
  }, [dispatch]);

  return (
    <BaseProjectList
      version="1"
      title="Experiences"
      href="/about/experiences"
      hrefText="View All"
    >
      {exps
        .filter((s, i) => i <= 7)
        .map((s) => {
          return (
            <BaseListItem key={s.id}>
              <Text>{s.title}</Text>
            </BaseListItem>
          );
        })}
    </BaseProjectList>
  );
};

export default ExperienceList;

const BaseProjectList = styled(Cards)`
  & > div {
    padding: 0.4rem 0 0;
  }
  @media screen and (max-width: ${responsiveness.mobile1}) {
    display: none;
  }
`;

const BaseListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gray200};
  padding: 0 1rem;

  p {
    margin: 0;
    padding: 0;
  }
`;
