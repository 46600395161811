import { Text, Title } from "../typography";
import { colors, fontSize, shadow } from "../../assets/styles/parameters";
import { useEffect, useRef } from "react";

import { AiOutlineCaretDown } from "react-icons/ai";
import styled from "styled-components";
import { useState } from "react";

export const ActionDropdown = ({
  iconTitle,
  title,
  children,
  icon,
  ...otherProps
}) => {
  const [show, setShow] = useState(false);
  const ref = useRef();
  const [width, setWidth] = useState("");

  const ShowDropdown = () => {
    return setShow(!show);
  };

  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, []);

  return (
    <BaseDiv
      {...otherProps}
      onMouseLeave={() => setShow(false)}
      ref={ref}
      width={width}
    >
      {iconTitle && (
        <Title number="6" onClick={() => ShowDropdown()}>
          {iconTitle}
        </Title>
      )}
      {icon && <AiOutlineCaretDown onClick={() => ShowDropdown()} />}
      <BaseContainer show={show}>
        <BaseContainerHeader>
          {title && <Text color="gray">{title}</Text>}
        </BaseContainerHeader>

        <div>{children}</div>
      </BaseContainer>
    </BaseDiv>
  );
};

export default ActionDropdown;

const BaseDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : null)};
  svg,
  h6 {
    cursor: pointer;
  }

  h6 {
    font-size: 2rem;
    padding: 0;
    margin: 0;
  }

  svg {
    font-size: 1.9;
  }
`;

const BaseContainer = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 70%; // Use bounding client rect to get the bottom of the title
  background: ${colors.white};
  box-shadow: ${shadow.dark};
  border-radius: 0 0 5px 5px;
  width: max-content;
  padding-right: 1rem;
  transition: all 0.4s ease-in;

  div:last-child {
    padding: 0.3rem 0.5rem;
    display: flex;
    flex-direction: column;

    li,
    p {
      margin: 0 0 0.2rem;
    }
  }
`;

const BaseContainerHeader = styled.header`
  padding: 0.3rem 0 0 0.5rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.gray200};
  margin-bottom: 0.3rem;

  p {
    padding: 0;
    margin: 0;
    font-size: ${fontSize.nav};
  }
`;
