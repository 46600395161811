import { Container, Section } from "../../components/section";

import { ContactForm } from "./ContactForm";
import { CreateContact } from "../../redux/contact";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const ContactCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFormSubmit = async (values) => {
    await dispatch(CreateContact(values));
    navigate("/messages");
  };

  return (
    <Section>
      <Container width="70">
        <ContactForm onFormSubmit={onFormSubmit} />
      </Container>
    </Section>
  );
};
