import { Field, Form } from "react-final-form";
import {
  FormContainer,
  Input,
  Label,
  RichTextField,
} from "../../components/inputs";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "../../components/button";
import { Grid } from "../../components/section";
import { ImageUploadComponent } from "../../components/imageUpload";
import { Text } from "../../components/typography";
import styled from "styled-components";

export const AboutForm = (props) => {
  const { onFormSubmit, initialValues, onHandleImageDelete } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/", { replace: true });
    return direction;
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, pristine, submitting, invalid, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <Grid col="l15">
            <div>
              <FormContainer>
                <Label text="About" />
                <Field name="about" component={RichTextField} />
              </FormContainer>{" "}
              <FormContainer>
                <Field
                  name="resume"
                  type="url"
                  component={Input}
                  label="Resume URL"
                  placeholder="Add resume url"
                />
              </FormContainer>
              <FormContainer>
                <Label text="Privacy" />
                <Field name="privacy" component={RichTextField} />
              </FormContainer>
              <FormContainer>
                <Label text="Terms of Use" />
                <Field name="terms" component={RichTextField} />
              </FormContainer>
            </div>
            <FormContainer>
              <Label text="Upload Gallery Images" />
              <Field name="files">
                {(props) => {
                  return (
                    <ImageUploadComponent
                      name={props.input.name}
                      files={props.input.value}
                      onupdatefiles={props.input.onChange}
                      onremoveImage={onHandleImageDelete}
                      extraProp={initialValues?.media}
                    />
                  );
                }}
              </Field>
            </FormContainer>
          </Grid>
          <ButtonFlex>
            <Text scale="small" onClick={() => onCloseandReset(reset)}>
              Cancel
            </Text>
            <Button
              type="submit"
              disabled={submitting || pristine || invalid}
              text={submitting ? "Saving..." : "Save"}
            />
          </ButtonFlex>
        </form>
      )}
    </Form>
  );
};
const ButtonFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a:link,
  p,
  button {
    margin: 0;
    cursor: pointer;
  }
`;
