import _ from "lodash";
import { cfAPI } from "../api";
import { toast } from "react-toastify";

export const EVENT_REQUEST = "EVENT_REQUEST";
export const EVENT_ERROR = "EVENT_ERROR";
export const EVENTSS_FETCH = "EVENTSS_FETCH";
export const EVENT_FETCH = "EVENT_FETCH";
export const EVENT_CREATE = "EVENT_CREATE";
export const EVENT_EDIT = "EVENT_EDIT";
export const EVENT_DELETE = "EVENT_DELETE";

const BaseRoute = "cfwebapi/api/v1/events";

export const FetchEvents = () => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_REQUEST,
    });

    const response = await cfAPI.get(BaseRoute);

    dispatch({
      type: EVENTSS_FETCH,
      payload:
        response.data && Array.isArray(response.data) ? response.data : [],
    });
  } catch (error) {
    dispatch({
      type: EVENT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchEvent = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_REQUEST,
    });

    const response = await cfAPI.get(`${BaseRoute}/${id}`);

    dispatch({
      type: EVENT_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CreateEvent = (values) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_REQUEST,
    });

    const formData = new FormData();

    const finalData = _.omit(
      {
        ...values,
        mediaType: "IMAGE",
      },
      "files"
    );

    if (values.files) {
      for (const file of values.files) {
        formData.append("files", file.file);
      }
    }

    const blob = new Blob([JSON.stringify(finalData)], {
      type: "application/json",
    });

    formData.append("data", blob);

    const response = await cfAPI.post(BaseRoute, formData);

    dispatch({
      type: EVENT_CREATE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UpdateEvent = (id, values) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_REQUEST,
    });

    const formData = new FormData();

    const finalData = _.omit(
      {
        ...values,
        mediaType: "IMAGE",
      },
      "files"
    );

    if (values.files) {
      for (const file of values.files) {
        formData.append("files", file.file);
      }
    }

    const blob = new Blob([JSON.stringify(finalData)], {
      type: "application/json",
    });

    formData.append("data", blob);

    const response = await cfAPI.patch(`${BaseRoute}/${id}`, formData);

    dispatch({
      type: EVENT_EDIT,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteEvent = (values) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_REQUEST,
    });

    const response = await cfAPI.delete(`${BaseRoute}/${values.id}`);

    dispatch({
      type: EVENT_DELETE,
      status: response.status,
      payload: values,
    });
  } catch (error) {
    dispatch({
      type: EVENT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

const INITIAL_STATE = {
  loading: false,
  events: [],
  event: {},
  error: "",
};

export const EventsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EVENT_REQUEST:
      return { ...state, loading: true };
    case EVENTSS_FETCH:
      return { ...state, loading: false, events: action.payload };
    case EVENT_FETCH:
      return { ...state, loading: false, event: action.payload };
    case EVENT_CREATE:
      toast.success("Event Created");
      return {
        ...state,
        loading: false,
      };
    case EVENT_EDIT:
      toast.success("Event Updated");
      return { ...state, loading: false, event: action.payload };
    case EVENT_DELETE:
      toast.success("Event Deleted");
      const newList = state.exps.filter((s) => s.id !== action.payload.id);
      return {
        ...state,
        loading: false,
        events: newList,
      };
    case EVENT_ERROR:
      toast.error("Something Bad Happened");
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
