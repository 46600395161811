import {
  DeleteGallery,
  DeleteGalleryPicture,
  UpdateGallery,
} from "../../redux/gallery";
import { Layout, Section } from "../../components/section";
import { useLocation, useNavigate } from "react-router-dom";

import { GalleryForm } from "./GalleryForm";
import { IoTrash } from "react-icons/io5";
import styled from "styled-components";
import { useDispatch } from "react-redux";

export const GalleryEdits = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save your edits?`)) {
      dispatch(UpdateGallery(state.gallery.id, values));
    }
  };

  const onDeleteSubmit = () => {
    if (window.confirm(`Are you sure you want to delete this?`)) {
      dispatch(DeleteGallery(state.gallery));
      navigate("/gallery");
    }
  };

  const onHandleImageDelete = (mediaSource, fileName) => {
    console.log(mediaSource);
    dispatch(DeleteGalleryPicture(mediaSource, fileName));
  };

  const InitialValues = {
    ...state.gallery,
    files: state?.gallery?.media?.mediaFiles,
  };

  return (
    <Layout>
      <Section>
        <SubNav>
          <li onClick={onDeleteSubmit}>
            <IoTrash />
          </li>
        </SubNav>

        <GalleryForm
          initialValues={InitialValues}
          onFormSubmit={onFormSubmit}
          onHandleImageDelete={onHandleImageDelete}
        />
      </Section>
    </Layout>
  );
};

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    color: red;

    p {
      margin: 0;
      padding: 0 0 0 0.5rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
