import { cfAPI } from "../api";
import { toast } from "react-toastify";

const DELETE_IMAGE = "DELETE_IMAGE";
const MEDIA_REQUEST = "MEDIA_REQUEST";
const MEDIA_ERROR = "MEDIA_ERROR";

const INITIAL_STATE = {
  loading: false,
  media: [],
  image: {},
  error: "",
};

export const MediaBaseRouteDelete = (name, fileName) =>
  `mediaapi/api/v1/media/byName/${name}/${fileName}`;

export const DeleteMedia = (name, fileName) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEDIA_REQUEST,
    });

    const response = await cfAPI.delete(MediaBaseRouteDelete(name, fileName));

    dispatch({
      type: DELETE_IMAGE,
      payload: response.data,
      status: response.status,
    });
  } catch (error) {
    dispatch({
      type: MEDIA_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const MediaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MEDIA_REQUEST:
      return { ...state, loading: true };
    case DELETE_IMAGE:
      toast.success("Image Deleted");
      return {
        ...state,
        loading: false,
      };
    case MEDIA_ERROR:
      toast.error("Something Bad Happened");
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
