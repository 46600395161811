import {
  ExperienceList,
  Gallery,
  MessageList,
  UpcomingShowsList,
  UserProfile,
} from "../elements/dashboard";
import { Grid, Layout, Section } from "../components/section";
import { colors, shadow } from "../assets/styles/parameters";

import { Cards } from "../components/cards";
import { Link } from "react-router-dom";
import { Title } from "../components/typography";
import portrait from "../assets/imgs/cford.webp";
import styled from "styled-components";
import { useSelector } from "react-redux";

export const Dashboard = () => {
  const { userInfo } = useSelector((state) => state.users);

  return (
    <Layout>
      <Section>
        <ContainerA col="4">
          <div>
            <BaseActionCard to="/events/create">
              <Title number="6">New Event</Title>
            </BaseActionCard>
            <BaseActionCard to="/about/experiences/create">
              <Title number="6">New Experiences</Title>
            </BaseActionCard>
            <BaseActionCard to="/gallery/create">
              <Title number="6">New Gallery</Title>
            </BaseActionCard>
            <BaseActionCard to="/press/create">
              <Title number="6">New Post</Title>
            </BaseActionCard>
          </div>
          <MessageList />
          <UpcomingShowsList />
        </ContainerA>
        <ContainerB>
          <Cards
            version="1"
            title="Recent Gallery"
            href="/gallery"
            hrefText="View Gallery"
          >
            <Gallery />
          </Cards>
          <ExperienceList />
          <UserProfile
            title={` ${userInfo.user?.firstName} ${userInfo.user?.lastName}`}
            path="/settings/profile"
            pathText="View Your Profile"
            image={portrait}
          />{" "}
        </ContainerB>
      </Section>
    </Layout>
  );
};

export default Dashboard;

const ContainerA = styled(Grid)`
  grid-template-columns: 0.5fr 0.5fr 1fr;
  gap: 1rem;
`;

const ContainerB = styled(Grid)`
  grid-template-columns: 0.5fr 1fr 0.5fr;
  gap: 1rem;
  margin-top: 0.5rem;
`;

const BaseActionCard = styled(Link)`
  background-color: ${colors.blue};
  color: ${colors.white};
  padding: 1rem 1rem;
  border-radius: 5px;
  box-shadow: ${shadow.light};
  text-transform: uppercase;
  min-width: 100%;
  display: block;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: ${colors.gold};
  }
`;

const CardInfo = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 1fr min-content;
  gap: 1rem;
  justify-content: space-between;
`;
