import { Grid } from "../../components/section";
import { PopUpRight } from "../../components/popups";
import styled from "styled-components";

export const GalleryDetailFetch = ({
  images,
  open,
  onToggleOpenClose,
  ...otherProps
}) => {
  return (
    <>
      <PopUpRight
        title="Gallery"
        open={open}
        width="90%"
        onToggleOpenClose={onToggleOpenClose}
      >
        {
          <>
            {otherProps.components && otherProps.components}
            <BaseGallery col="2">
              {images?.map((s) => {
                return <img key={s.fileName} src={s.url} alt="hello" />;
              })}
            </BaseGallery>
          </>
        }
      </PopUpRight>
    </>
  );
};

export default GalleryDetailFetch;

const BaseGallery = styled(Grid)`
  img {
    height: min(500px, 600px);
    object-fit: cover;
  }
`;
