import {
  CheckboxInput,
  CustomCalendar,
  FormContainer,
  Input,
  Label,
  RichTextField,
} from "../../components/inputs";
import { Field, Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "../../components/button";
import { Grid } from "../../components/section";
import { ImageUploadComponent } from "../../components/imageUpload";
import { Text } from "../../components/typography";
import styled from "styled-components";

export const BlogForm = (props) => {
  const { onFormSubmit, initialValues, onHandleImageDelete } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/", { replace: true });
    return direction;
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, pristine, submitting, invalid, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <Grid col="l15">
            <div>
              {" "}
              <FormContainer>
                <Field
                  name="title"
                  type="text"
                  component={Input}
                  label="Title"
                  placeholder="What is the article name?"
                />
              </FormContainer>
              <FormContainer>
                <Label>Article Body</Label>
                <Field name="description" component={RichTextField} />
              </FormContainer>
              <FormContainer>
                <Grid>
                  <Field
                    name="date"
                    type="text"
                    component={CustomCalendar}
                    label="Publish Date"
                  />
                </Grid>
              </FormContainer>
              <FormContainer>
                <Grid>
                  <Field
                    label="Feature"
                    name="featured"
                    type="checkbox"
                    component={CheckboxInput}
                  />
                </Grid>
              </FormContainer>
            </div>
            <FormContainer>
              <Label text="Upload Gallery Images" />
              <Field name="files">
                {(props) => {
                  return (
                    <ImageUploadComponent
                      name={props.input.name}
                      files={props.input.value}
                      onupdatefiles={props.input.onChange}
                      onremoveImage={onHandleImageDelete}
                      extraProp={initialValues?.media}
                    />
                  );
                }}
              </Field>
            </FormContainer>
          </Grid>
          <ButtonFlex>
            <Text scale="small" onClick={() => onCloseandReset(reset)}>
              Cancel
            </Text>
            <Button
              type="submit"
              disabled={submitting || pristine || invalid}
              text={submitting ? "Saving..." : "Save"}
            />
          </ButtonFlex>
        </form>
      )}
    </Form>
  );
};
const ButtonFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a:link,
  p,
  button {
    margin: 0;
    cursor: pointer;
  }
`;
