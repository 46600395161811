import { DeleteGallery, FetchGalleries } from "../../redux/gallery";
import { Grid, Layout, Section } from "../../components/section";
import { IoAddCircleOutline, IoPencilOutline, IoTrash } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { AppLinks } from "../../components/links";
import { Cards } from "../../components/cards";
import { GalleryDetailFetch } from "./GalleryDetailFetch";
import { Link } from "react-router-dom";
import { PageHeader } from "../../components/header";
import styled from "styled-components";

export const GalleryList = () => {
  const [open, SetOpen] = useState(false);
  const [selected, SetSelected] = useState({});

  const dispatch = useDispatch();
  const { galleries } = useSelector((state) => state.gallery);

  const onToggleOpenClose = () => {
    SetOpen(!open);
  };

  const onDisplayImages = (data) => {
    onToggleOpenClose();
    SetSelected(data);
  };

  const onRemoveDisplayImage = () => {
    onToggleOpenClose();
    SetSelected({});
  };

  const onDeleteSubmit = (value) => {
    if (window.confirm("Are you sure you want to delete this?")) {
      dispatch(DeleteGallery(value));
      onToggleOpenClose();
    }
  };

  useEffect(() => {
    dispatch(FetchGalleries());
  }, [dispatch]);

  return (
    <>
      <PageHeader title="Gallery">
        <Link to={`/gallery/create`}>
          <IoAddCircleOutline />
        </Link>
      </PageHeader>
      <Layout>
        <BaseGalleryList>
          <Grid col="3">
            {galleries?.map((s, i) => {
              return (
                <>
                  <Cards
                    key={i}
                    title={s.title}
                    description={s.description}
                    image={s?.media?.mediaFiles[0]?.url}
                    imageDescription={`${s.title} image ${i + 1}`}
                    onClick={() => onDisplayImages(s)}
                  />
                </>
              );
            })}
          </Grid>
        </BaseGalleryList>
        <GalleryDetailFetch
          images={selected?.media?.mediaFiles}
          open={open}
          onToggleOpenClose={onRemoveDisplayImage}
          components={
            <div>
              <IoTrash onClick={() => onDeleteSubmit(selected)} />
              <AppLinks
                to={`/gallery/${selected.id}/edit`}
                state={{ gallery: selected }}
              >
                <IoPencilOutline />
              </AppLinks>
            </div>
          }
        />
      </Layout>
    </>
  );
};

export default GalleryList;

const BaseGalleryList = styled(Section)`
  padding-top: 0rem;
`;
