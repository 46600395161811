export const USER_REQUEST = "USER_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_ERROR = "USER_ERROR";

export const USER_PROFILE_EDIT = "USER_PROFILE_EDIT";
export const USER_PROFILE_LOGOUT = "USER_PROFILE_LOGOUT";
export const USER_PROFILE_LOGOUT_FAIL = "USER_PROFILE_LOGOUT_FAIL";

export const USER_SIGN_UP_REQUEST = "USER_SIGN_UP_REQUEST";
export const USER_SIGN_UP_SUCCESS = "USER_SIGN_UP_SUCCESS";
export const USER_SIGN_UP_FAIL = "USER_SIGN_UP_FAIL";
export const USER_SIGN_UP_CONFIRMATION = "USER_SIGN_UP_CONFIRMATION";

export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const NAV_OPEN = "NAV_OPEN";
export const RESET_STATE = "RESET_STATE";
export const USER_REFRESH_TOKEN = "USER_REFRESH_TOKEN";
export const USER_FORGOT_PASSWORD_REQUEST_FAIL =
  "USER_FORGOT_PASSWORD_REQUEST_FAIL";
