import {
  CheckboxInput,
  CustomCalendar,
  CustomTextArea,
  FormContainer,
  Input,
  Label,
} from "../../components/inputs";
import { Field, Form } from "react-final-form";
import { IoAddCircleOutline, IoTrashOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";

import { BaseCard } from "../../components/cards/Cards";
import { Button } from "../../components/button";
import { FieldArray } from "react-final-form-arrays";
import { Grid } from "../../components/section";
import { ImageUploadComponent } from "../../components/imageUpload";
import { Text } from "../../components/typography";
import arrayMutators from "final-form-arrays";
import styled from "styled-components";

export const ExperienceForm = (props) => {
  const { onFormSubmit, initialValues, onHandleImageDelete } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/", { replace: true });
    return direction;
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({
        handleSubmit,
        pristine,
        submitting,
        invalid,
        form: {
          reset,
          mutators: { push },
        },
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid col="l15">
            <div>
              <FormContainer>
                <Field
                  name="title"
                  type="text"
                  component={Input}
                  label="Title"
                  placeholder="What is the project title?"
                />
              </FormContainer>
              <FormContainer>
                <Field
                  name="description"
                  label="Describe the experience."
                  component={CustomTextArea}
                />
              </FormContainer>
              <FormContainer>
                <Field
                  name="date"
                  type="text"
                  component={CustomCalendar}
                  label="Date"
                />
              </FormContainer>
              <FormContainer>
                <Field
                  name="category"
                  type="text"
                  component={Input}
                  label="Category"
                />
              </FormContainer>
              <Grid>
                <FormContainer>
                  <Field
                    name="location"
                    type="text"
                    component={Input}
                    label="City"
                    placeholder="What is the award name?"
                  />
                </FormContainer>
                <FormContainer>
                  <Field
                    name="studio"
                    type="text"
                    component={Input}
                    label="Studio"
                    placeholder="What is the award name?"
                  />
                </FormContainer>
              </Grid>
              <FormContainer>
                <Field
                  name="url"
                  type="text"
                  component={Input}
                  label="Link"
                  placeholder="What is the award name?"
                />
              </FormContainer>
              <BaseListCard>
                <div>
                  <Label text="Videos" />
                  <IoAddCircleOutline
                    onClick={() => push("videos", undefined)}
                    disabled={submitting}
                  />
                </div>
                <FieldArray name="videos">
                  {({ fields }) => (
                    <>
                      {fields.map((video, index) => (
                        <BaseContent key={index}>
                          <Field
                            name={`${video}.title`}
                            type="text"
                            component={Input}
                            label="Title"
                          />
                          <Field
                            name={`${video}.url`}
                            type="url"
                            component={Input}
                            label="URL"
                          />

                          <Field
                            name={`${video}.description`}
                            type="text"
                            component={Input}
                            label="Description"
                          />
                          <IoTrashOutline
                            color="red"
                            onClick={() => fields.remove(index)}
                          />
                        </BaseContent>
                      ))}
                    </>
                  )}
                </FieldArray>
              </BaseListCard>
              <FormContainer>
                <Field
                  label="Featured"
                  name="featured"
                  type="checkbox"
                  component={CheckboxInput}
                />
                <Field
                  label="Upcoming"
                  name="upcoming"
                  type="checkbox"
                  component={CheckboxInput}
                />{" "}
                <Field
                  label="Archive"
                  name="archive"
                  type="checkbox"
                  component={CheckboxInput}
                />
              </FormContainer>
            </div>
            <div>
              <FormContainer>
                <Field name="files">
                  {(props) => {
                    return (
                      <ImageUploadComponent
                        name={props.input.name}
                        files={props.input.value}
                        onupdatefiles={props.input.onChange}
                        onremoveImage={onHandleImageDelete}
                        extraProp={initialValues?.media}
                      />
                    );
                  }}
                </Field>
              </FormContainer>
            </div>
          </Grid>
          <ButtonFlex>
            <Text scale="small" onClick={() => onCloseandReset(reset)}>
              Cancel
            </Text>
            <Button
              type="submit"
              disabled={submitting || pristine || invalid}
              text={submitting ? "Saving..." : "Save"}
            />
          </ButtonFlex>
        </form>
      )}
    </Form>
  );
};

const ButtonFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a:link,
  p,
  button {
    margin: 0;
    cursor: pointer;
  }
`;

const BaseListCard = styled(BaseCard)`
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  margin: 0;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    padding-top: 0.7rem;
  }

  & > svg {
    font-size: 1.2rem;
    place-self: center;
    cursor: pointer;
    margin-left: 1rem;

    &:is(:hover, :focus) {
      color: green;
    }
  }
`;

const BaseContent = styled.div`
  label {
    margin: 0;
  }

  svg {
    font-size: 1.2rem;
    place-self: center;
    cursor: pointer;
  }
`;
