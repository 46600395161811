import styled, { css } from "styled-components";

import { Link } from "react-router-dom";
import { Text } from "../typography";
import { colors } from "../../assets/styles/parameters";

export const Button = ({
  version,
  varient,
  size,
  path,
  text,
  type,
  state,
  ...otherProps
}) => {
  switch (version) {
    case "link":
      return (
        <StyledButton size={size} ty={varient} type={type} {...otherProps}>
          <Link to={path} {...otherProps} state={state}>
            {text}
          </Link>
        </StyledButton>
      );
    default:
      return (
        <StyledButton size={size} ty={varient} type={type} {...otherProps}>
          <Text>{text}</Text>
        </StyledButton>
      );
  }
};

const BaseButton = styled.button`
  &,
  &:link,
  &:visited {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    width: max-content;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.65;
    cursor: auto;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`;

const Varients = ({ ty }) => {
  switch (ty) {
    case "outlineWH":
      return css`
        background: transparent;
        border: 1px solid ${colors.white};
        color: ${colors.white};

        a,
        p {
          color: ${colors.white};
        }
      `;
    case "outlineBK":
      return css`
        background: transparent;
        border: 1px solid ${colors.black};
        color: ${colors.black};

        a,
        p {
          color: ${colors.black};
        }
      `;
    case "outlineGold":
      return css`
        background: transparent;
        border: 1px solid ${colors.gold};
        color: ${colors.gold};

        a,
        p {
          color: ${colors.gold};
        }
      `;
    case "gold":
      return css`
        background: ${colors.gold};
        border: 1px solid ${colors.gold};
        color: ${colors.white};

        a,
        p {
          color: ${colors.white};
        }
      `;
    default:
      return css`
        background: ${colors.black};
        border: 1px solid ${colors.black};
        color: ${colors.white};

        a,
        p {
          color: ${colors.white};
        }
      `;
  }
};

const Sizes = ({ size }) => {
  switch (size) {
    default:
      return css`
        a,
        p {
          display: inline-block;
          padding: 0.6rem 2rem;
          margin: 0;
        }
      `;
  }
};

const Shape = ({ shape }) => {
  switch (shape) {
    case "circle":
      return css`
        border-radius: 50%;
        padding: 0.75rem 1rem;
      `;
    default:
      return css`
        border-radius: 50px;
      `;
  }
};

const StyledButton = styled(BaseButton)`
  ${Sizes}
  ${Varients}
  ${Shape}
`;
