import { cfAPI } from "../api";
import { toast } from "react-toastify";

export const CONTACT_REQUEST = "CONTACT_REQUEST";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const CONTACTS_FETCH = "CONTACTS_FETCH";
export const CONTACT_FETCH = "CONTACT_FETCH";
export const CONTACT_CREATE = "CONTACT_CREATE";
export const CONTACT_EDIT = "CONTACT_EDIT";
export const CONTACT_DELETE = "CONTACT_DELETE";

const INITIAL_STATE = {
  loading: false,
  contacts: [],
  contact: {},
  error: "",
};

const BaseRoute = "cfwebapi/api/v1/contacts";

export const FetchContacts = () => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_REQUEST,
    });

    const response = await cfAPI.get(BaseRoute);

    dispatch({
      type: CONTACTS_FETCH,
      payload:
        response.data && Array.isArray(response.data) ? response.data : [],
    });
  } catch (error) {
    dispatch({
      type: CONTACT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchContact = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_REQUEST,
    });

    const response = await cfAPI.get(`${BaseRoute}/${id}`);

    dispatch({
      type: CONTACT_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CreateContact = (values) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_REQUEST,
    });

    const response = await cfAPI.post(BaseRoute, values);

    dispatch({
      type: CONTACT_CREATE,
      payload: response.status,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const EditContact = (value) => async (dispatch, getState) => {
  const currentContacts = getState().contacts.contacts.filter((message) => {
    return message.id !== value.id;
  });

  const route =
    value.metadata.messageRead === true ? "markAsRead" : "markAsUnRead";

  try {
    dispatch({
      type: CONTACT_REQUEST,
    });

    const response = await cfAPI.patch(
      `${BaseRoute}/${value.id}/${route}`,
      value
    );

    const newContact = [...currentContacts, response.data];

    dispatch({
      type: CONTACT_EDIT,
      payload: newContact,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteContact = (values) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_REQUEST,
    });

    const response = await cfAPI.delete(`${BaseRoute}?id=${values.id}`);

    dispatch({
      type: CONTACT_DELETE,
      status: response.status,
      payload: values,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ContactReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONTACT_REQUEST:
      return { ...state, loading: true };
    case CONTACTS_FETCH:
      return { ...state, loading: false, contacts: action.payload };
    case CONTACT_FETCH:
      return { ...state, loading: false, contact: action.payload };
    case CONTACT_CREATE:
      toast.success("Message Created");
      return {
        ...state,
        loading: false,
      };
    case CONTACT_EDIT:
      return { ...state, loading: false, contacts: action.payload };
    case CONTACT_DELETE:
      toast.success("Message Deleted");
      const newList = state.contacts.filter((s) => s.id !== action.payload.id);
      return {
        ...state,
        loading: false,
        contacts: newList,
      };
    case CONTACT_ERROR:
      toast.error("Something Bad Happened");
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
