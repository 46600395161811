import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  bodyWeight,
  colors,
  fontFamily,
  fontSize,
  lineHeight,
} from "./parameters";

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
*, 
*::before, 
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 1.2;
}

html{
    font-size: ${fontSize.def};
}

body{
    background: ${colors.gray100};
    font-family: ${fontFamily.body};
    line-height: ${lineHeight.body};
    font-weight: 200;
}

h1, h2, h3, h4, h5, h6{
    margin: 0 0 0.5rem;
    font-family: ${fontFamily.headings};
    font-weight: ${bodyWeight.w3};
    line-height: ${lineHeight.headings}
}


h1{
    margin-top: 0;
}


a{
    text-decoration: none;
    color: ${colors.black}
}

ol, li, ul{
    list-style: none;
}

img{
    width: 100%;
}


select {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;   
}


.swal-overlay {
  background-color: rgba(0, 0, 0, 0.45);
}

.swal-modal {
  border-radius: 2px;
}

.swal-title {
  margin: 0px;
  font-size: 16px;
}

.swal-button {
  background: ${colors.white};
  color: ${colors.black};
  border: 1px solid ${colors.blue};
  border-radius: 2px;

}

.swal-footer {
  background-color: ${colors.blue};
  margin-top: 32px;
  overflow: hidden;
}


.filepond--drop-label {
  background-color: ${colors.gray200};
  border-radius: 5px;
}

.filepond--wrapper {
  margin-top: 1.6rem
}

.img-uploader-text {
  color: #000
}
.filepond--credits {
  display: none;
}

.carousel .slide{
    background: #ff442e!important;
}

table.mceLayout, textarea.tinyMCE {
    width: 100% !important;
}

.mceToolbar td {
    display:table-row;
    float: left;
}
.mceToolbar td:nth-of-type(11){
    clear: left;
}

.slick-slide.slick-center img {
        transform: scale(1.2);
    }
    
@media only screen and (min-width: 600px) {
    table.mceLayout, textarea.richEditor {
       width: 600px !important;
    }
    
    .mceToolbar td {
        display:table-cell;
        float: none;
    }
    mceToolbar td:nth-of-type(11){
        clear: none;
    }
}

`;
