import CustomSelect from "react-select";
import { InputContainer } from "./Inputs";
import { Label } from "./Inputs";
import { colors } from "../../assets/styles/parameters";

export const Select = ({
  name,
  options = [],
  value,
  onChange,
  className,
  emptyValueLabel,
  placeholder,
  noOptionsMessage,
  ...otherProps
}) => {
  return (
    <CustomSelect
      {...otherProps}
      className={className}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      name={name}
      options={options}
      isSearchable={false}
      maxMenuHeight={150}
      noOptionsMessage={noOptionsMessage}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: "none",
          boxShadow: "none",
        }),
        container: (baseStyles, state) => ({
          ...baseStyles,
          margin: 0,
        }),
        indicatorSeparator: () => ({}),
        menuList: (baseStyles, state) => ({
          ...baseStyles,
          border: "1px black solid",
        }),
        menu: (base) => ({
          ...base,
          marginTop: 0,
          zIndex: 1000,
        }),
        valueContainer: (base) => ({
          ...base,
          padding: 0,
          margin: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            color: colors.black,
            backgroundColor: isFocused
              ? colors.gray300
              : isSelected
              ? colors.gray100
              : colors.white,
            padding: "0",
            textAlign: "center",
            cursor: "pointer",
          };
        },
      }}
    />
  );
};

export const SelectFinalForm = ({
  input,
  label,
  options,
  entity,
  ...otherProps
}) => {
  return (
    <InputContainer>
      {label && <Label text={label} entity={entity} />}
      <CustomSelect
        className={otherProps.className}
        onChange={input.onChange}
        value={input.value}
        placeholder={otherProps.placeholder}
        name={otherProps.name}
        options={options}
        isSearchable={false}
        maxMenuHeight={150}
        noOptionsMessage={otherProps.noOptionsMessage}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: "none",
            boxShadow: "none",
            backgroundColor: colors.gray200,
          }),
          container: (baseStyles, state) => ({
            ...baseStyles,
            margin: 0,
          }),
          indicatorSeparator: () => ({}),
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            border: "1px black solid",
          }),
          menu: (base) => ({
            ...base,
            marginTop: 0,
            zIndex: 1000,
          }),
          valueContainer: (base) => ({
            ...base,
            padding: "0.2rem 0.5rem",
            margin: 0,
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              color: colors.black,
              backgroundColor: isFocused
                ? colors.gray300
                : isSelected
                ? colors.gray100
                : colors.white,
              padding: "0",
              textAlign: "center",
              cursor: "pointer",
            };
          },
        }}
        {...otherProps}
      />
    </InputContainer>
  );
};
