import { AwardsForm } from "./AwardsForm";
import { CreateAward } from "../../redux/awards";
import { Section } from "../../components/section";
import { useDispatch } from "react-redux";

export const AwardsCreate = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to add this award`)) {
      dispatch(CreateAward(values));
    }
  };

  return (
    <Section>
      <AwardsForm onFormSubmit={onFormSubmit} />
    </Section>
  );
};
