import { Container, Layout } from "../components/section";

import { Outlet } from "react-router-dom";
import { PageHeader } from "../components/header";
import { Title } from "../components/typography";

export const Resources = () => {
  return (
    <>
      <PageHeader title="Resources"></PageHeader>
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export const Users = () => {
  return (
    <Container>
      <Title number="6">User Accounts</Title>
      <Outlet />
    </Container>
  );
};
