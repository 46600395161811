import { IoMailOpenOutline, IoMailOutline } from "react-icons/io5";
import { Text, Title } from "../../components/typography";

import { Grid } from "../../components/section";
import { colors } from "../../assets/styles/parameters";
import moment from "moment";
import styled from "styled-components";

export const ContactDataTableExpandable = ({
  data,
  onUpdateReadStatus,
  ...otherProps
}) => {
  const onReadTimer = () => {
    if (data.metadata.messageRead !== true) {
      setTimeout(onUpdateReadStatus(data, "read"), 100000);
    }
  };

  return (
    <BaseContainer {...otherProps} onClick={() => onReadTimer()}>
      <Grid>
        <div>
          <Text scale="small" color="gray">
            Contacted Date and Time:{" "}
            {moment(data.createdDate).format("MMM DD, YYYY - h:mm a")}
          </Text>
          <Title number={5}>
            {data.firstName} {data.lastName}
          </Title>
          <Text scale="small">
            <a href={`tel:${data.phone}`}>{data.phone}</a>
            <br />
            <a href={`mailto:${data.email}`}>{data.email}</a>
          </Text>
        </div>
        <div>
          <Text scale="small" color="gray">
            Message
          </Text>
          <Text>{data.message}</Text>
        </div>
      </Grid>
      <ActionContainer>
        <Text scale="small" color="gray">
          Actions
        </Text>
        <div>
          <div onClick={() => onUpdateReadStatus(data)}>
            {data.metadata.messageRead ? (
              <div>
                <IoMailOpenOutline /> <Text>Read</Text>
              </div>
            ) : (
              <div>
                <IoMailOutline /> <Text>Unread</Text>
              </div>
            )}
          </div>
        </div>
      </ActionContainer>
    </BaseContainer>
  );
};

const BaseContainer = styled.div`
  padding: 1rem 3rem;
`;

export const ActionContainer = styled.div`
  border-top: 1px solid ${colors.gray300};
  padding-top: 0.5rem;
  margin-top: 1rem;

  & > div {
    display: flex;
    cursor: pointer;
    width: min-content;

    div {
      font-size: 0.8rem;
      width: min-content;
    }
  }
`;

export const RowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
    cursor: pointer;

    &:is(:hover, :focus) {
      color: red;
    }
  }
`;
