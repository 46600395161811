import { NavReducer, UserPassword, UserReducer } from "./user/UserReducer";

import { AboutReducer } from "./about";
import { AccountsReducer } from "./accounts";
import { AwardsReducer } from "./awards";
import { BlogReducer } from "./blog";
import { ContactReducer } from "./contact";
import { EventsReducer } from "./events";
import { ExperiencesReducer } from "./experience";
import { FAQReducers } from "./faq";
import { GalleryReducer } from "./gallery";
import { MediaReducer } from "./media";
import { ServiceReducer } from "./service/ServiceReducer";
import { StaffsReducer } from "./staff/StaffReducer";
import { combineReducers } from "redux";

export const rootReducer = combineReducers({
  users: UserReducer,
  nav: NavReducer,
  contacts: ContactReducer,
  accounts: AccountsReducer,
  staff: StaffsReducer,
  services: ServiceReducer,
  media: MediaReducer,
  about: AboutReducer,
  faqs: FAQReducers,
  blogs: BlogReducer,
  awards: AwardsReducer,
  gallery: GalleryReducer,
  exps: ExperiencesReducer,
  pass: UserPassword,
  events: EventsReducer,
});
