import { DeleteEvent, UpdateEvent } from "../../redux/events";

import { EventForm } from "./EventForm";
import { IoTrash } from "react-icons/io5";
import { Section } from "../../components/section";
import { Text } from "../../components/typography";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export const EventEdit = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save your edits?`)) {
      const address = {
        street: values.street || " ",
        city: values.city || " ",
        state: values.state || " ",
        zipcode: values.zipcode || " ",
      };

      console.log("Date:", values.dateTime);

      const newValues = {
        ...values,
        description: values.description || " ",
        address,
      };

      dispatch(UpdateEvent(state.event.id, newValues));
    }
  };

  const onDeleteSubmit = () => {
    if (window.confirm(`Are you sure you want to delete this?`)) {
      dispatch(DeleteEvent(state.event));
    }
  };

  const InitialValues = {
    ...state.event,
    street: state.event?.address?.street,
    city: state.event?.address?.city,
    state: state.event?.address?.state,
    zipcode: state.event?.address?.zipcode,
  };

  console.log(state.event);

  return (
    <Section>
      <SubNav>
        <li onClick={onDeleteSubmit}>
          <IoTrash />
          <Text>Delete</Text>
        </li>
      </SubNav>
      <EventForm initialValues={InitialValues} onFormSubmit={onFormSubmit} />
    </Section>
  );
};

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    color: red;

    p {
      margin: 0;
      padding: 0 0 0 0.5rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
