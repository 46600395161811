import {
  NAV_OPEN,
  RESET_STATE,
  USER_ERROR,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_PROFILE_EDIT,
  USER_PROFILE_LOGOUT,
  USER_PROFILE_LOGOUT_FAIL,
  USER_REFRESH_TOKEN,
  USER_REQUEST,
  USER_SIGN_UP_FAIL,
  USER_SIGN_UP_REQUEST,
  USER_SIGN_UP_SUCCESS,
} from "./UserTypes";

import { cfAPI } from "../../api";

const BaseRoute = "/userapi/api/v1";

export const UserRefreshToken = (accessToken) => (dispatch) => {
  dispatch({
    type: USER_REFRESH_TOKEN,
    payload: accessToken,
  });
};

const UserLoginFunc = async ({ usernameOrEmail, password }) => {
  const response = await cfAPI.post(`${BaseRoute}/auth/signin`, {
    usernameOrEmail,
    password,
  });

  return response;
};

const GetLoggedInUserFunc = async ({ token, email }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await cfAPI.get(`${BaseRoute}/user/${email}`, config);

  return response;
};

export const UserLogIn = (formValues) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REQUEST,
    });

    const newFormValues = {
      usernameOrEmail: formValues.email,
      password: formValues.password,
    };

    const { headers } = await UserLoginFunc(newFormValues);
    const response = await GetLoggedInUserFunc(headers);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: {
        token: headers.token,
        refreshtoken: headers.refreshtoken,
        user: response.data,
      },
      status: response.status,
    });
  } catch (error) {
    dispatch({
      type: USER_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UserProfileUpdate = (formValues) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REQUEST,
    });

    const { firstName, lastName, email } = formValues;

    const response = await cfAPI.patch(`${BaseRoute}/user/${email}`, {
      firstName,
      lastName,
      email,
    });
    dispatch({
      type: USER_PROFILE_EDIT,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: USER_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UserSignUp = (formValues) => async (dispatch) => {
  try {
    dispatch({
      type: USER_SIGN_UP_REQUEST,
    });

    const response = await cfAPI.post(`${BaseRoute}/auth/signup`, formValues);

    dispatch({
      type: USER_SIGN_UP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: USER_SIGN_UP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UserLogout = () => (dispatch) => {
  try {
    localStorage.removeItem("persist:293939fk");
    sessionStorage.removeItem("persist:293939fk");

    dispatch({ type: USER_PROFILE_LOGOUT });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_LOGOUT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const PasswordReset = (formValues) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REQUEST,
    });

    const response = await cfAPI.post(
      `${BaseRoute}/auth/initiate-password-reset`,
      formValues
    );
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: USER_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const PasswordResetToken = (formValues) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REQUEST,
    });

    const response = await cfAPI.patch(
      `${BaseRoute}/auth/reset-password`,
      formValues
    );
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: USER_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const PassResetState = {
  type: RESET_STATE,
};

export const ShowNav = {
  type: NAV_OPEN,
};
