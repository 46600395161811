import { CreateExperience } from "../../redux/experience";
import { ExperienceForm } from "./ExpForm";
import { Section } from "../../components/section";
import { useDispatch } from "react-redux";

export const ExperienceCreate = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to add this award`)) {
      console.log(values);
      dispatch(CreateExperience(values));
    }
  };

  return (
    <Section>
      <ExperienceForm onFormSubmit={onFormSubmit} />
    </Section>
  );
};
