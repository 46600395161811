import { TransferList } from "@mantine/core";
import { useState } from "react";

export function TransferListInput(props) {
  const { input, permission, ...otherProps } = props;

  const [data, setData] = useState([
    permission?.filter((a) =>
      input?.value ? !input?.value.some((b) => a.id === b.id) : []
    ),
    input?.value ? input?.value : [],
  ]);

  return (
    <TransferList
      value={data}
      onChange={(value) => {
        input.onChange(value);
        setData(value);
      }}
      searchPlaceholder="Search permissions..."
      nothingFound="No permission found"
      titles={["Available permissions", "Current user permissions"]}
      listHeight={300}
      breakpoint="sm"
      filter={(query, item) =>
        item?.label?.toLowerCase().includes(query.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(query.toLowerCase().trim())
      }
      {...otherProps}
    />
  );
}
