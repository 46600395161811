import { cfAPI } from "../api";
import { toast } from "react-toastify";

export const ADMIN_USERS_PROFILE_GET_ALL = "ADMIN_USERS_PROFILE_GET_ALL";
export const ADMIN_USERS_PROFILE_GET_USER = "ADMIN_USERS_PROFILE_GET_USER";
export const ADMIN_USERS_PROFILE_EDIT = "ADMIN_USERS_PROFILE_EDIT";
export const ADMIN_USERS_PROFILE_DELETE = "ADMIN_USERS_PROFILE_DELETE";
export const ADMIN_USERS_PROFILE_REQUEST = "ADMIN_USERS_PROFILE_REQUEST";
export const ADMIN_USERS_PROFILE_FAIL = "ADMIN_USERS_PROFILE_FAIL";
export const ADMIN_USERS_PROFILE_PERMISSION = "ADMIN_USERS_PROFILE_PERMISSION";

const INITIAL_STATE = {
  loading: false,
  accounts: [],
  account: {},
  permissions: [],
  error: "",
};

const BaseRoute = "userapi/api/v1/admin/user/userinfo";

export const FetchAccounts = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_USERS_PROFILE_REQUEST,
    });

    const response = await cfAPI.get(BaseRoute);

    dispatch({
      type: ADMIN_USERS_PROFILE_GET_ALL,
      payload:
        response.data && Array.isArray(response.data) ? response.data : [],
    });
  } catch (error) {
    dispatch({
      type: ADMIN_USERS_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchAccount = (email, username) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_USERS_PROFILE_REQUEST,
    });

    const response = await cfAPI.get(`${BaseRoute}/${email}`);

    dispatch({
      type: ADMIN_USERS_PROFILE_GET_USER,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_USERS_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UpdateUserAccount = (id, values) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_USERS_PROFILE_REQUEST,
    });

    const response = await cfAPI.patch(`${BaseRoute}/${id}`, values);

    dispatch({
      type: ADMIN_USERS_PROFILE_EDIT,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_USERS_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

const BasePermissions = "userapi/api/v1/admin/user/permissions";

export const FetchAccountPermission = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_USERS_PROFILE_REQUEST,
    });

    const response = await cfAPI.get(BasePermissions);

    dispatch({
      type: ADMIN_USERS_PROFILE_PERMISSION,
      payload:
        response.data && Array.isArray(response.data) ? response.data : [],
    });
  } catch (error) {
    dispatch({
      type: ADMIN_USERS_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const AccountsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_USERS_PROFILE_REQUEST:
      return { ...state, loading: true };
    case ADMIN_USERS_PROFILE_GET_ALL:
      return { ...state, loading: false, accounts: action.payload };
    case ADMIN_USERS_PROFILE_GET_USER:
      return { ...state, loading: false, account: action.payload };
    case ADMIN_USERS_PROFILE_PERMISSION:
      return { ...state, loading: false, permissions: action.payload };
    case ADMIN_USERS_PROFILE_EDIT:
      toast.success("User Account Updated");
      return { ...state, loading: false, account: action.payload };
    case ADMIN_USERS_PROFILE_FAIL:
      toast.error("Something Bad Happened");
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
