import { Navigation, Sidebar } from "../components/navigation";
import { useEffect, useState } from "react";

import { AutoLogout } from "./AutoLogout";
import { Loader } from "../components/loader";
import { Login } from "../pages";
import { Outlet } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { responsiveness } from "../assets/styles/parameters";
import { store } from "../redux/store";
import styled from "styled-components";
import { useSelector } from "react-redux";

export const ProtectedRoutes = () => {
  const { userInfo } = useSelector((state) => state.users);

  const persistor = persistStore(store);

  return userInfo?.token ? (
    <>
      <AutoLogout>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <Layout>
            <Sidebar />
            <Navigation />
            <Outlet />
          </Layout>
        </PersistGate>
      </AutoLogout>
    </>
  ) : (
    <Login />
  );
};

const Layout = styled.div`
  display: grid;
  grid-template-areas:
    "sidebar navigation navigation navigation "
    "sidebar header header header"
    "sidebar main main main"
    "sidebar main main main"
    "sidebar main main main";
  grid-template-columns: 5% 1fr 1fr 1fr;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "navigation"
      "sidebar"
      "header"
      "main";
  }
`;
