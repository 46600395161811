import { Link } from "react-router-dom";
import styled from "styled-components";

export const AppLinks = ({ to, children, ...otherProps }) => {
  return (
    <BaseStyle to={to} {...otherProps}>
      {children}
    </BaseStyle>
  );
};

const BaseStyle = styled(Link)`
  margin-left: 1rem;
`;
