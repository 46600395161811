import { IoAddCircleOutline, IoPencilOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import { AppLinks } from "../../components/links";
import { Cards } from "../../components/cards";
import { FetchAbout } from "../../redux/about";
import { FetchAwards } from "../../redux/awards";
import { FetchExperiences } from "../../redux/experience";
import { Section } from "../../components/section";
import { Title } from "../../components/typography";
import parse from "html-react-parser";
import styled from "styled-components";
import { useEffect } from "react";

export const AboutList = () => {
  const { about, awards, exps } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchAbout());
    dispatch(FetchAwards());
    dispatch(FetchExperiences());
  }, [dispatch]);

  console.log(about.abouts);
  return (
    <>
      <Section>
        <Cards
          version="3"
          title="About"
          href={
            about?.abouts?.length !== 0
              ? `/about/profile/${about?.abouts[0]?.id}/edit`
              : "/about/create"
          }
          hrefText={about.abouts[0] ? "Edit" : "Create"}
          state={{ about: about?.abouts[0] }}
        >
          {about.abouts[0]?.about && parse(`${about.abouts[0]?.about}`)}
        </Cards>
      </Section>
      <Section>
        <SectionHeading>
          <Title number="6">Award</Title>
          <AppLinks to={`/about/awards/create`}>
            <IoAddCircleOutline />
          </AppLinks>
        </SectionHeading>
        <AwardsSection>
          {awards.awards.map((s) => {
            return (
              <Cards
                version="3"
                title={s.title}
                href={`/about/awards/${s.id}/edit`}
                hrefText={<IoPencilOutline />}
                state={{ awards: s }}
              />
            );
          })}
        </AwardsSection>
      </Section>
      <Section>
        <SectionHeading>
          <Title number="6">Experience</Title>
          <AppLinks to={`/about/experiences/create`}>
            <IoAddCircleOutline />
          </AppLinks>
        </SectionHeading>
        <AwardsSection>
          {exps.exps.map((s) => {
            return (
              <Cards
                version="3"
                title={s.title}
                href={`/about/experiences/${s.id}/edit`}
                hrefText={<IoPencilOutline />}
                state={{ experience: s }}
              />
            );
          })}
        </AwardsSection>
      </Section>
    </>
  );
};
const SectionHeading = styled.div`
  padding: 1rem 1rem 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  a:link,
  p,
  button {
    margin: 0;
    cursor: pointer;
  }
`;

const AwardsSection = styled.div`
  margin-bottom: 2rem;
  & > div {
    margin-bottom: 0;
  }
`;
