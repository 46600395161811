import { CustomTextArea, Input } from "../../components/inputs";
import { Field, Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "../../components/button";
import { Grid } from "../../components/section";
import { Text } from "../../components/typography";
import styled from "styled-components";

export const ContactForm = (props) => {
  const { onFormSubmit, initialValues } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const onCancelandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/messages", { replace: true });
    return direction;
  };

  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        pristine,
        submitting,
        invalid,
        form: { reset },
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid>
            <Field
              name="firstName"
              type="text"
              component={Input}
              label="First Name"
              entity="&#10029;"
            />
            <Field
              name="lastName"
              type="text"
              component={Input}
              label="Last Name"
              entity="&#10029;"
            />
          </Grid>
          <Field
            name="organization"
            type="text"
            component={Input}
            label="Organization"
          />
          <Field
            name="reason"
            type="text"
            component={Input}
            label="Subject"
            entity="&#10029;"
          />
          <Grid>
            <Field
              name="phone"
              type="tel"
              component={Input}
              label="Phone Number"
              entity="&#10029;"
            />

            <Field
              name="email"
              type="email"
              component={Input}
              label="Email"
              entity="&#10029;"
            />
          </Grid>
          <Field
            name="message"
            type="text"
            component={CustomTextArea}
            label="Message"
            entity="&#10029;"
          />
          <ButtonFlex>
            <Text scale="small" onClick={() => onCancelandReset(reset)}>
              Cancel
            </Text>
            <Button
              type="submit"
              disabled={submitting || pristine || invalid}
              text="Enter Message Details"
            >
              {submitting ? "Saving....." : "Save"}
            </Button>
          </ButtonFlex>
        </form>
      )}
    />
  );
};

export default ContactForm;

const ButtonFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a:link,
  p,
  button {
    margin: 0;
    cursor: pointer;
  }
`;
