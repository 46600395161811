import { colors, fontSize, shadow } from "../../assets/styles/parameters";

import { Button } from "../button";
import { Link } from "react-router-dom";
import { Title } from "../typography";
import img from "../../assets/imgs/cford.webp";
import styled from "styled-components";

export const Cards = ({
  version,
  title,
  description,
  href,
  hrefText,
  children,
  btn,
  image,
  imageDescription,
  ...otherProps
}) => {
  switch (version) {
    case "profile":
      return (
        <BaseProfile {...otherProps}>
          {href ? (
            <Link to={href}>
              <img src={otherProps.portrait || img} alt="Headshot" />
            </Link>
          ) : (
            <img src={otherProps.portrait || img} alt="Headshot" />
          )}
        </BaseProfile>
      );
    case "4":
      return (
        <CardFour {...otherProps}>
          <img
            src={image || otherProps.portrait}
            alt={imageDescription || "img from user"}
          />
        </CardFour>
      );
    case "3":
      return (
        <CardThree {...otherProps}>
          <div>
            <Title number="6">{title}</Title>
            <Link to={href} state={otherProps.state}>
              {hrefText}
            </Link>
          </div>
          {children && <div> {children}</div>}
        </CardThree>
      );
    case "2":
      return (
        <CardTwo {...otherProps}>
          <Title number="6">{title}</Title>
          <div> {children}</div>
        </CardTwo>
      );
    case "1":
      return (
        <CardOne {...otherProps}>
          <Title number="6">{title}</Title>
          <ul>{children}</ul>
          {href ? <Button version="link" path={href} text={hrefText} /> : null}
        </CardOne>
      );
    default:
      return (
        <ImageCard {...otherProps}>
          <img
            src={image || otherProps.portrait}
            alt={imageDescription || "img from user"}
          />
          {title && <Title number="6">{title}</Title>}
        </ImageCard>
      );
  }
};

const ImageCard = styled.div`
  height: min(500px, 800px);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${shadow.light};

  button {
    margin-top: 2rem;
    justify-self: flex-end;
  }

  img {
    width: 100%;
    height: min(460px, 800px);
    object-fit: cover;
  }
`;

export const BaseCard = styled.div`
  padding: 1rem 0 0.5rem 0;
  border-radius: 5px;
  background-color: ${colors.white};
  transition: all 0.3s ease-in;
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: ${({ center }) => (center ? "center" : "left")};
  /* margin: 1rem 0 2rem 0; */
  box-shadow: ${shadow.light};

  & > h6 {
    padding: 0 1rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
  }

  & > div,
  ul {
    border-top: 1px solid ${colors.gray200};
  }
`;

const CardOne = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  font-size: ${fontSize.small};

  button {
    align-self: center;
    margin-top: 1rem;
  }

  & > ul {
    padding: 1rem 1rem;
  }
`;

export const CardTwo = styled(BaseCard)`
  font-size: ${fontSize.small};

  & > div {
    padding: 1rem 1rem 0;
  }
`;

export const CardThree = styled(BaseCard)`
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    border-top: 0;
    padding-top: 0;
  }

  & > div {
    padding: 1rem 1rem 0;
  }
`;

const BaseProfile = styled.div`
  height: max-content;

  img {
    display: block;
    width: 120px;
    height: 120px;
    clip-path: circle();
    object-fit: cover;
    margin-bottom: 1.6rem;
  }

  h5 {
    text-align: center;
  }
`;

const CardFour = styled.div`
  border-radius: 5px;
  height: 100%;
  padding: 0.2rem;

  img {
    border-radius: 5px;
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
`;
