import { BlogForm } from "./BlogForm";
import { CreateBlog } from "../../redux/blog";
import { Section } from "../../components/section";
import { useDispatch } from "react-redux";

export const BlogCreate = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    console.log("Blog Values: ", values);

    if (window.confirm(`Are you sure you want to add this article`)) {
      dispatch(CreateBlog(values));
    }
  };

  return (
    <Section>
      <BlogForm onFormSubmit={onFormSubmit} />
    </Section>
  );
};
