import { Grid, Section } from "../../components/section";
import { Text, Title } from "../../components/typography";

import { UsersCard as AccountCard } from "./Users";
import { Cards } from "../../components/cards";
import { UserProfile as ProfileCard } from "../dashboard";
import portrait from "../../assets/imgs/cford.webp";
import { useSelector } from "react-redux";

export const ResourcesDashboard = () => {
  const { userInfo } = useSelector((state) => state.users);

  return (
    <Section>
      <Title number="5">Settings</Title>
      <Grid col="2">
        <ProfileCard
          title={` ${userInfo.user?.firstName} ${userInfo.user?.lastName}`}
          path="/settings/profile"
          pathText="View Your Profile"
          image={portrait}
        />
        {userInfo?.user?.role === "ADMIN" ? <AccountCard /> : null}
      </Grid>
      <Cards version="1" title="Technical Support">
        <Text>
          For technical support, please contact website administrator.
        </Text>
      </Cards>
    </Section>
  );
};
