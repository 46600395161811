import {
  fontFamily,
  fontSize,
  responsiveness,
} from "../../assets/styles/parameters";

import { Editor } from "@tinymce/tinymce-react";
import { Span } from "../typography";
import styled from "styled-components";
import { useRef } from "react";

export const RichTextField = (props) => {
  const { input, meta, ...otherProps } = props;
  const { touched, valid, error } = meta;
  const editorRef = useRef(null);

  const onChange = (content) => {
    input.onChange(content);
  };

  return (
    <InputContainer {...otherProps}>
      <Editor
        {...otherProps}
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={input.value}
        init={{
          height: 500,
          menubar: true,
          template_mdate_format: "%m/%d/%Y : %H:%M",
          plugins: [
            "advlist autolink charmap | print anchor",
            "searchreplace visualblocks code",
            "insert accordion datetime media table paste help",
            "image",
            "lists",
            "link",
            "preview",
            "wordcount",
            "autolink",
            "code",
          ],
          toolbar:
            "advlist undo redo | " +
            "bold italic backcolor | alignleft aligncenter alignright alignjustify | outdent indent | " +
            "numlist bullist accordion |" +
            "preview |" +
            "link image | removeformat code",
          fullpage_default_doctype: "<!DOCTYPE html>",
          selector: "textarea",
          a11y_advanced_options: true,
          branding: false,

          content_style: `body { font-family:${fontFamily.body}, Arial,sans-serif; font-size:${fontSize.default}}`,
          image_list: [],
          mobile: {
            menubar: true,
            theme: "mobile",
          },
          resize: true,
          style_formats_merge: true,
        }}
        onEditorChange={onChange}
      />
      {!valid && touched && error ? (
        <Span color="red" size="small">
          {error}
        </Span>
      ) : null}
    </InputContainer>
  );
};

export default RichTextField;

const InputContainer = styled.div`
  margin-bottom: 1rem;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    margin-bottom: 2rem;
  }
`;
