import {
  About,
  Awards,
  Blog,
  Contact,
  Event,
  Experience,
  Gallery,
} from "../pages";
import { AboutCreate, AboutEdit, AboutList } from "../elements/about";
import { AwardsCreate, AwardsEdit, AwardsList } from "../elements/awards";
import { BlogCreate, BlogEdit, BlogList } from "../elements/blog";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ContactCreate, ContactList } from "../elements/contact";
import { Dashboard, ErrorPage, Logout, SignUp } from "../pages";
import { EventCreate, EventEdit, EventList } from "../elements/events";
import {
  ExperienceCreate,
  ExperienceEdit,
  ExperienceList,
} from "../elements/experience";
import { GalleryCreate, GalleryEdits, GalleryList } from "../elements/gallery";

import { AnimatePresence } from "framer-motion";
import { ProtectedRoutes } from "./ProtectedRoutes";
import ScrollToTop from "../utils/ScrollToTop";
import SettingsRoute from "./settingsRoutes";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AnimatePresence>
        <Routes>
          <Route path="/" exact element={<ProtectedRoutes />}>
            <Route index element={<Dashboard />} />
            <Route path="messages" element={<Contact />}>
              <Route index element={<ContactList />} />
              <Route path="create" element={<ContactCreate />} />
            </Route>
            <Route path="about" element={<About />}>
              <Route index element={<AboutList />} />{" "}
              <Route path="create" element={<AboutCreate />} />
              <Route path="profile/:id/edit" element={<AboutEdit />} />
            </Route>
            <Route path="about/awards" element={<Awards />}>
              <Route index element={<AwardsList />} />{" "}
              <Route path="create" element={<AwardsCreate />} />
              <Route path=":id/edit" element={<AwardsEdit />} />
            </Route>
            <Route path="about/experiences" element={<Experience />}>
              <Route index element={<ExperienceList />} />{" "}
              <Route path="create" element={<ExperienceCreate />} />
              <Route path=":id/edit" element={<ExperienceEdit />} />
            </Route>
            <Route path="gallery" element={<Gallery />}>
              <Route index element={<GalleryList />} />
              <Route path="create" element={<GalleryCreate />} />
              <Route path=":id/edit" element={<GalleryEdits />} />
            </Route>

            <Route path="press" element={<Blog />}>
              <Route index element={<BlogList />} />
              <Route path="create" element={<BlogCreate />} />
              <Route path=":id/edit" element={<BlogEdit />} />
            </Route>
            <Route path="events" element={<Event />}>
              <Route index element={<EventList />} />
              <Route path="create" element={<EventCreate />} />
              <Route path=":id/edit" element={<EventEdit />} />
            </Route>
            {SettingsRoute()}
            <Route path="/signup" element={<SignUp />} />
          </Route>
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );
};

export default AppRoutes;
