import { colors, responsiveness } from "../../assets/styles/parameters";
import styled, { css } from "styled-components";

import { NavLink } from "react-router-dom";

const NavLinkItem = ({ name, path, highlight, ...otherProps }) => {
  return (
    <BaseListItem>
      <NavLink to={path} highlight={highlight} {...otherProps}>
        {name}
      </NavLink>
    </BaseListItem>
  );
};

export default NavLinkItem;

const BaseListItem = styled.li`
  margin-right: 2rem;
  white-space: nowrap;

  a {
    ${(props) => {
      switch (props.children.props.highlight) {
        case "true":
          return css`
            color: ${colors.red};
          `;
        default:
          return css`
            color: none;
          `;
      }
    }};
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    margin: 3rem 0 0 0;
  }
`;
