import styled, { css } from "styled-components";

import { colors } from "../../assets/styles/parameters";

export const Status = ({ status }) => {
  return <Wrapper status={status}>{status}</Wrapper>;
};

export default Status;

const Wrapper = styled.div`
  padding: 0.1rem 0.5rem;
  border-radius: 30px;
  opacity: 0.8;
  font-size: 0.7rem;
  width: min-content;
  display: inline-block;
  white-space: nowrap;

  ${({ status }) => {
    switch (status) {
      case "Read":
        return css`
          background-color: green;
          color: ${colors.white};
        `;
      case "Unread":
        return css`
          background-color: red;
          color: ${colors.white};
        `;
      default:
        return css`
          background-color: ${colors.gray500};
        `;
    }
  }}
`;
