import { BaseInput, BaseLabel } from "./Inputs";

import { Text } from "../typography";

export const CustomCalendar = (props) => {
  const { input, label, ...otherProps } = props;

  return (
    <div {...otherProps}>
      <BaseLabel>
        <Text>{label}</Text>
      </BaseLabel>
      <BaseInput
        type="datetime-local"
        value={input.value}
        onChange={input.onChange}
        {...otherProps}
      />
    </div>
  );
};
