import { Container, Grid, Section } from "../../components/section";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "../../components/button";
import { Input } from "../../components/inputs";
import { Text } from "../../components/typography";
import { UserProfileUpdate } from "../../redux/user/UserActions";
import arrayMutators from "final-form-arrays";
import styled from "styled-components";

export const Profile = () => {
  const { userInfo } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/settings", { replace: true });
    return direction;
  };

  const onSubmit = (values) => {
    dispatch(UserProfileUpdate(values));
  };

  return (
    <Section>
      <Container width="60" margin="auto">
        <Form
          onSubmit={onSubmit}
          initialValues={userInfo?.user}
          mutators={{
            ...arrayMutators,
          }}
          validate={(formValues) => {
            const errors = {};

            return errors;
          }}
        >
          {({
            handleSubmit,
            pristine,
            submitting,
            invalid,
            form: {
              mutators: { push },
              reset,
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <Grid>
                  <Field
                    label="First Name"
                    name="firstName"
                    type="text"
                    component={Input}
                    entity="&#10029;"
                  />

                  <Field
                    label="Last Name"
                    name="lastName"
                    type="text"
                    component={Input}
                    entity="&#10029;"
                  />
                </Grid>

                <Field
                  name="email"
                  type="email"
                  component={Input}
                  label="Email"
                  entity="&#10029;"
                />
              </div>
              <ButtonFlex>
                <Text scale="small" onClick={() => onCloseandReset(reset)}>
                  Cancel
                </Text>
                <Button
                  type="submit"
                  disabled={submitting || pristine || invalid}
                  text="Enter Profile Details"
                >
                  {submitting ? "Saving....." : "Save"}
                </Button>
              </ButtonFlex>
            </form>
          )}
        </Form>
      </Container>
    </Section>
  );
};

const ButtonFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a:link,
  p,
  button {
    margin: 0;
    cursor: pointer;
  }
`;
