import { AboutForm } from "./AboutForm";
import { CreateAbout } from "../../redux/about";
import { Section } from "../../components/section";
import { useDispatch } from "react-redux";

export const AboutCreate = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to add this`)) {
      dispatch(CreateAbout(values));
    }
  };

  return (
    <Section>
      <AboutForm onFormSubmit={onFormSubmit} />
    </Section>
  );
};
