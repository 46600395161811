import {
  IoCheckboxOutline,
  IoCloseSharp,
  IoPencilOutline,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "react-data-table-component";
import { FetchExperiences } from "../../redux/experience";
import { Link } from "react-router-dom";
import Moment from "moment";
import { Section } from "../../components/section";
import styled from "styled-components";
import { useEffect } from "react";

export const ExperienceList = () => {
  const { exps } = useSelector((state) => state.exps);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchExperiences());
  }, [dispatch]);

  const columns = [
    {
      name: "Date",
      selector: (row) => {
        return Moment(row.date).format("MMM DD, YYYY");
      },
      sortable: true,
      width: "150px",
      hide: "md",
    },
    {
      name: "Show/Program",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Studio",
      selector: (row) => row.studio,
      sortable: true,
      hide: "sm",
    },
    {
      name: "City",
      selector: (row) => row.location,
      sortable: true,
      hide: "md",
    },
    {
      name: "Featured",
      sortable: true,
      center: true,
      selector: (row) => (
        <div>{row.featured ? <IoCheckboxOutline /> : <IoCloseSharp />}</div>
      ),
      hide: "md",
      width: "100px",
    },
    {
      name: "Archive",
      sortable: true,
      center: true,
      selector: (row) => (
        <div>{row.archive ? <IoCheckboxOutline /> : <IoCloseSharp />}</div>
      ),
      hide: "md",
      width: "100px",
    },
    {
      name: "Upcoming",
      sortable: true,
      center: true,
      selector: (row) => (
        <div>{row.upcoming ? <IoCheckboxOutline /> : <IoCloseSharp />}</div>
      ),
      hide: "md",
      width: "100px",
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <Link
            to={`/about/experiences/${row.id}/edit`}
            state={{ experience: row }}
          >
            <IoPencilOutline />
          </Link>
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      center: true,
      width: "100px",
      name: "Actions",
    },
  ];

  return (
    <>
      <Section>
        <DataTable
          columns={columns}
          data={exps}
          striped
          persistTableHead
          pagination
          highlightOnHover
          compact
          fixedHeader
          responsive
        />
      </Section>
    </>
  );
};

const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;
