import { MediaBaseRouteDelete } from "./media";
import _ from "lodash";
import { cfAPI } from "../api";
import { toast } from "react-toastify";

const ABOUT_REQUEST = "ABOUT_REQUEST";
const ABOUT_ERROR = "ABOUT_ERROR";

const ABOUTS_FETCH = "ABOUTS_FETCH";
const ABOUT_FETCH = "ABOUT_FETCH";
const ABOUT_CREATE = "ABOUT_CREATE";
const ABOUT_DELETE_IMAGE = "ABOUT_DELETE_IMAGE";
const ABOUT_EDIT = "ABOUT_EDIT";
const ABOUT_DELETE = "ABOUT_DELETE";

const BaseRoute = "cfwebapi/api/v1/misc";

export const FetchAbout = () => async (dispatch) => {
  try {
    dispatch({
      type: ABOUT_REQUEST,
    });

    const response = await cfAPI.get(BaseRoute);

    dispatch({
      type: ABOUTS_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ABOUT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CreateAbout = (values) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ABOUT_REQUEST,
    });

    const formData = new FormData();

    const finalData = _.omit(
      {
        ...values,
        mediaType: "IMAGE",
      },
      "files"
    );

    if (values.files) {
      for (const file of values.files) {
        formData.append("files", file.file);
      }
    }

    const blob = new Blob([JSON.stringify(finalData)], {
      type: "application/json",
    });

    formData.append("data", blob);

    const response = await cfAPI.post(BaseRoute, formData);

    dispatch({
      type: ABOUT_CREATE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ABOUT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UpdateAbout = (id, values) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ABOUT_REQUEST,
    });

    const formData = new FormData();

    const finalData = _.omit(
      {
        ...values,
        mediaType: "IMAGE",
      },
      "files"
    );

    if (values.files) {
      for (const file of values.files) {
        formData.append("files", file.file);
      }
    }

    const blob = new Blob([JSON.stringify(finalData)], {
      type: "application/json",
    });

    formData.append("data", blob);

    const response = await cfAPI.patch(`${BaseRoute}/${id}`, formData);

    dispatch({
      type: ABOUT_EDIT,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ABOUT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteAboutPicture = (name, fileName) => async (dispatch) => {
  try {
    dispatch({
      type: ABOUT_REQUEST,
    });

    const response = await cfAPI.delete(MediaBaseRouteDelete(name, fileName));

    dispatch({
      type: ABOUT_DELETE_IMAGE,
      payload: response.data,
      status: response.status,
    });
  } catch (error) {
    dispatch({
      type: ABOUT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteAbout = (values) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ABOUT_REQUEST,
    });

    const response = await cfAPI.delete(`${BaseRoute}/${values.id}`);

    dispatch({
      type: ABOUT_DELETE,
      payload: values,
      status: response.status,
    });
  } catch (error) {
    dispatch({
      type: ABOUT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

const INITIAL_STATE = {
  loading: false,
  abouts: [],
  about: {},
  error: "",
};

export const AboutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ABOUT_REQUEST:
      return { ...state, loading: true };
    case ABOUTS_FETCH:
      return { ...state, loading: false, abouts: action.payload };
    case ABOUT_FETCH:
      return { ...state, loading: false, about: action.payload };
    case ABOUT_CREATE:
      toast.success("Company Created");
      return {
        ...state,
        loading: false,
        about: action.payload,
      };
    case ABOUT_EDIT:
      toast.success("Update Successful");
      return { ...state, loading: false, about: action.payload };
    case ABOUT_DELETE:
      toast.success("Delete Successful");
      return {
        ...state,
        loading: false,
      };
    case ABOUT_DELETE_IMAGE:
      toast.success("Image Deleted");
      return {
        ...state,
        loading: false,
        about: action.payload,
      };
    case ABOUT_ERROR:
      toast.error("Something Bad Happened");
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
