import { MediaBaseRouteDelete } from "./media";
import _ from "lodash";
import { cfAPI } from "../api";
import { toast } from "react-toastify";
const GALLERIES_FETCH = "GALLERIES_FETCH";
const GALLERY_FETCH = "GALLERY_FETCH";
const GALLERY_CREATE = "GALLERY_CREATE";
const GALLERY_EDIT = "GALLERY_EDIT";
const GALLERY_DELETE = "GALLERY_DELETE";
const GALLERY_DELETE_IMAGE = "GALLERY_DELETE_IMAGE";
const GALLERY_ERROR = "GALLERY_ERROR";
const GALLERY_REQUEST = "GALLERY_REQUEST";

const BaseRoute = "cfwebapi/api/v1/galleries";

export const CreateGallery = (values) => async (dispatch) => {
  try {
    dispatch({
      type: GALLERY_CREATE,
    });

    const formData = new FormData();

    const finalData = _.omit(
      {
        ...values,
        description: values.description || " ",
        mediaType: "IMAGE",
      },
      "files"
    );

    if (values.files) {
      for (const file of values.files) {
        formData.append("files", file.file);
      }
    }

    const blob = new Blob([JSON.stringify(finalData)], {
      type: "application/json",
    });

    formData.append("data", blob);

    const response = await cfAPI.post(BaseRoute, formData);

    dispatch({
      type: GALLERY_CREATE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GALLERY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchGalleries = () => async (dispatch) => {
  try {
    dispatch({
      type: GALLERY_REQUEST,
    });

    const response = await cfAPI.get(BaseRoute);

    dispatch({
      type: GALLERIES_FETCH,
      payload:
        response.data && Array.isArray(response.data) ? response.data : [],
    });
  } catch (error) {
    dispatch({
      type: GALLERY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchGallery = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GALLERY_REQUEST,
    });

    const response = await cfAPI.get(`${BaseRoute}/${id}`);
    dispatch({
      type: GALLERY_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GALLERY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UpdateGallery = (id, values) => async (dispatch) => {
  try {
    dispatch({
      type: GALLERY_REQUEST,
    });

    const formData = new FormData();

    const finalData = _.omit(
      {
        ...values,
        description: values.description || " ",
        mediaType: "IMAGE",
      },
      "files"
    );

    if (values.files) {
      for (const file of values.files) {
        formData.append("files", file.file);
      }
    }

    const blob = new Blob([JSON.stringify(finalData)], {
      type: "application/json",
    });

    formData.append("data", blob);

    const response = await cfAPI.patch(`${BaseRoute}/${id}`, formData);

    dispatch({
      type: GALLERY_EDIT,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GALLERY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteGalleryPicture = (name, fileName) => async (dispatch) => {
  try {
    dispatch({
      type: GALLERY_REQUEST,
    });

    const response = await cfAPI.delete(MediaBaseRouteDelete(name, fileName));

    dispatch({
      type: GALLERY_DELETE_IMAGE,
      payload: response.data,
      status: response.status,
    });
  } catch (error) {
    dispatch({
      type: GALLERY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteGallery = (values) => async (dispatch) => {
  try {
    dispatch({
      type: GALLERY_REQUEST,
    });

    const response = await cfAPI.delete(`${BaseRoute}/${values.id}`);

    dispatch({
      type: GALLERY_DELETE,
      status: response.status,
      payload: values,
    });
  } catch (error) {
    dispatch({
      type: GALLERY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

const INITIAL_STATE = {
  loading: false,
  galleries: [],
  gallery: {},
  error: "",
};

export const GalleryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GALLERY_REQUEST:
      return { ...state, loading: true };
    case GALLERIES_FETCH:
      return {
        ...state,
        loading: false,
        galleries: action.payload,
      };
    case GALLERY_FETCH:
      return {
        ...state,
        loading: false,
        gallery: action.payload,
      };
    case GALLERY_CREATE:
      toast.success("Gallery Created");
      return {
        ...state,
        loading: false,
        gallery: action.payload,
      };

    case GALLERY_EDIT:
      toast.success("Gallery Updated");
      return {
        ...state,
        loading: false,
        gallery: action.payload,
      };

    case GALLERY_DELETE:
      toast.success("Gallery Deleted!");
      const newList = state.galleries.filter((s) => s.id !== action.payload.id);
      return {
        ...state,
        loading: false,
        galleries: newList,
      };
    case GALLERY_DELETE_IMAGE:
      toast.success("Image Deleted");
      return {
        ...state,
        loading: false,
        gallery: action.payload,
      };

    case GALLERY_ERROR:
      toast.error("Something Bad Happened");
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
