import { cfAPI } from "../api";
import { toast } from "react-toastify";

export const FAQ_ERROR = "FAQ_ERROR";
export const FAQ_REQUEST = "FAQ_REQUEST";
export const FAQ_FETCH = "FAQ_FETCH";
export const FAQ_FETCHES = "FAQ_FETCHES";
export const FAQ_CREATE = "FAQ_CREATE";
export const FAQ_EDIT = "FAQ_EDIT";
export const FAQ_DELETE = "FAQ_DELETE";

const BaseRoute = "companyapi/api/v1/faqs";

export const FetchFAQS = () => async (dispatch) => {
  try {
    dispatch({
      type: FAQ_REQUEST,
    });

    const response = await cfAPI.get(BaseRoute);

    dispatch({
      type: FAQ_FETCHES,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FAQ_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchFAQ = (id) => async (dispatch) => {
  try {
    dispatch({
      type: FAQ_REQUEST,
    });

    const response = await cfAPI.get(`${BaseRoute}/${id}`);

    dispatch({
      type: FAQ_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FAQ_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CreateFAQ = (values) => async (dispatch) => {
  try {
    dispatch({
      type: FAQ_REQUEST,
    });

    const response = await cfAPI.post(BaseRoute, values);
    dispatch({
      type: FAQ_CREATE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FAQ_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UpdateFAQ = (id, values) => async (dispatch) => {
  try {
    dispatch({
      type: FAQ_REQUEST,
    });

    const response = await cfAPI.patch(`${BaseRoute}/${id}`, values);

    dispatch({
      type: FAQ_EDIT,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FAQ_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteFAQ = (id, values) => async (dispatch) => {
  try {
    dispatch({
      type: FAQ_REQUEST,
    });

    const response = await cfAPI.delete(`${BaseRoute}/${values.id}`);

    dispatch({
      type: FAQ_DELETE,
      payload: values,
      status: response.status,
    });
  } catch (error) {
    dispatch({
      type: FAQ_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FAQReducers = (state = {}, action) => {
  switch (action.type) {
    case FAQ_REQUEST:
      return { ...state, loading: true };
    case FAQ_FETCHES:
      return { ...state, loading: false, faqs: action.payload };
    case FAQ_FETCH:
      return { ...state, loading: false, faq: action.payload };
    case FAQ_CREATE:
      toast.success("FAQ Created");
      return { ...state, loading: false, faq: action.payload };
    case FAQ_EDIT:
      toast.success("FAQ Updated");
      return { ...state, loading: false, faq: action.payload };
    case FAQ_DELETE:
      toast.success("FAQ Deleted");
      return {
        ...state,
        loading: false,
      };
    case FAQ_ERROR:
      toast.error("Something Bad Happened");
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
